import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import customerAPIURLs from './customer-API-URLs';
import { AppUtils } from '../../shared/utilities/app-utils';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AffiliateService } from '@dialog-eservices-enablement/angular-components';

@Injectable({
  providedIn: 'root'
})
export class CustomerNotificationServiceService {
  public notificationTableData: any;
  public notificationData: any;
  public accessToken = '';
  public header = {};
  public data: any;
  public notificationId: any;
  public customerId: any;
  public apiKey = customerAPIURLs.apiKey;
  public storeNotificationTableData: any;
  private notificationLocalId = new Subject<string>();
  public localId$ = this.notificationLocalId.asObservable();
  private accountSelector = new BehaviorSubject<string>('My notifications');
  public accountSelector$ = this.accountSelector.asObservable();
  public isDDVisible = new Subject<boolean>();

  public constructor(public http: HttpClient, public appUtil: AppUtils, private router: Router, private route: ActivatedRoute, private affiliateService: AffiliateService, public readonly oneSnackBar: OneSnackBarService) {}

  public getLocalStorageItem(text: string): string {
    for (let i = 0; i < localStorage.length; i++) {
      const lsKeyName: string = localStorage.key(i);
      if (lsKeyName.includes(text)) {
        return localStorage.getItem(lsKeyName);
      }
    }
  }
  public setAccessToken(): void {
    this.accessToken = this.getLocalStorageItem('accessToken');
    this.setHttpHeader();
  }
  public setHttpHeader(): void {
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.accessToken
      })
    };
  }
  public getNotificationTableData(type, status): Observable<any> {
    this.setAccessToken();
    this.setHttpHeader();
    const apiurl = environment.appUrl 
    +customerAPIURLs.baseURLParam 
    +"overview"
    +"?typeFilter="+type
    +"&statusFilter="+status;
    return this.http.get(apiurl, this.header);
  }

  public getNotificationDetails(rowData: any): Observable<any> {
    this.notificationId = rowData.NotificationId;
    this.customerId = rowData.CustomerId;
    this.setAccessToken();
    this.setHttpHeader();
    const apiurl = environment.appUrl + customerAPIURLs.baseURLParam + customerAPIURLs.notificationDetailsData + this.notificationId + customerAPIURLs.customerIdData + this.customerId;
    return this.http.get(apiurl, this.header);
  }
  public getService(url: string): Observable<any> {
    return this.http.get(url, this.header);
  }

  public getServiceNoHeader(url: string): Observable<any> {
    return this.http.get(url);
  }

  public downloadPiosDocumentApi(PIMID: any, fileExtension: string): void {
    const apiurl = environment.appUrl + customerAPIURLs.downloadDocument + PIMID;
    this.appUtil.fetchDoc(apiurl, this.apiKey, this.accessToken, fileExtension);
  }

  public downloadLocalAttachmentApi(filename: string, notificationId: string, contactNotificationId: string, publicKey: string): void {
    const userType = localStorage.getItem('userType');
    let apiurl = '';
    if (userType === 'isNonDialogUser') {
      apiurl = environment.appUrl + customerAPIURLs.nonDialogDownloadUrl + 'filename=' + filename + '&notificationid=' + notificationId + '&contactnotificationid=' + contactNotificationId + '&publickey=' + publicKey;
      this.getServiceNoHeader(apiurl).subscribe(
        res => {
          if (res) {
            const downloadURL = res.body; // )['uploadURL'];
            this.appUtil.fetchDoc(downloadURL, '', '', filename);
          }
        },
        (error: HttpErrorResponse) => {
          // console.log('error:', error);
          this.httpErrorHandler(error, 'Could not fetch Document Data.');
        }
      );
    } else {
      apiurl = environment.appUrl + customerAPIURLs.baseURLParam + customerAPIURLs.downloadLocalAttachment + 'Filename=' + filename + '&NotificationId=' + notificationId;
      this.getService(apiurl).subscribe(
        res => {
          if (res) {
            const downloadURL = res.body; // )['uploadURL'];
            this.appUtil.fetchDoc(downloadURL, '', '', filename);
          }
        },
        (error: HttpErrorResponse) => {
          // console.log('error:', error);
          this.httpErrorHandler(error, 'Could not fetch Document Data.');
        }
      );
    }
  }
  public httpErrorHandler(error: any, errMessage: string): void {
    if (error.status === 401) {
      const errorMessage = this.appUtil.getTranslatedMessage('401');
      this.oneSnackBar.open(errorMessage, OneSnackBarType.ERROR, '', { duration: 5000 });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      this.oneSnackBar.open(errMessage, OneSnackBarType.ERROR, '', { duration: 5000 });
    }
  }
  public putService(feedBackData: any): Observable<any> {
    const userType = localStorage.getItem('userType');
    this.setAccessToken();
    this.setHttpHeader();
    let apiurl = '';
    if (userType === 'isNonDialogUser') {
      apiurl = environment.appUrl + customerAPIURLs.nonDialogFeedbackSubmit + 'notificationid=' + feedBackData.NotificationId + '&contactnotificationid=' + feedBackData.ContactNotificationId + '&publickey=' + feedBackData.PublicKeyToken;
      return this.http.put(apiurl, feedBackData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      apiurl = environment.appUrl + customerAPIURLs.feedBackParam;
      return this.http.put(apiurl, feedBackData, this.header);
    }
  }

  public getNotificationDetailsDirectLink(urlData: any): Observable<any> {
    // ?notificationId=9e25dcd1-9e56-4960-a447-a44039e9ad65&contactNotificationId=fc9dde01-57dd-4caf-bbda-da7e69284962&publicKeyToken=3021e833-1f28-40f6-b1a1-294a04ed8cc0&country=GB
    const notificationId = this.getUrlVar(urlData, 'notificationId');
    const contactNotificationId = this.getUrlVar(urlData, 'contactNotificationId');
    const publicKeyToken = this.getUrlVar(urlData, 'publicKeyToken');
    const customerId = this.getUrlVar(urlData, 'customerId');
    this.setAccessToken();
    this.setHttpHeader();
    const detailAPIBaseUrl = environment.appUrl + customerAPIURLs.baseURLParam + customerAPIURLs.notificationDetailsData + notificationId;
    const apiParam = contactNotificationId && contactNotificationId !== 'undefined' ? '&contactnotificationid=' + contactNotificationId : '&customerid=' + customerId;
    let apiurl = detailAPIBaseUrl + apiParam;
    if (publicKeyToken && publicKeyToken !== 'undefined') {
      apiurl = environment.appUrl + customerAPIURLs.notificationDetailsNonDialogData + 'notificationid=' + notificationId + '&contactnotificationid=' + contactNotificationId + '&publickey=' + publicKeyToken;
      return this.http.get(apiurl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.http.get(apiurl, this.header);
    }
  }

  public getUrlVar(urlString: any, key: any): any {
    const result = new RegExp(key + '=([^&]*)', 'i').exec(urlString);
    return (result && unescape(result[1])) || '';
  }

  public getAffiliateCountries(): any {
    const affiliateData = this.affiliateService.affiliates;
    const countryList = affiliateData.map(affilate => affilate.name);
    return countryList;
  }

  public setLocalId(localid: string): void {
    this.notificationLocalId.next(localid);
  }

  public getAccountDropdownList(): Observable<any> {
    this.setAccessToken();
    this.setHttpHeader();
    const apiurl = environment.appUrl 
    +customerAPIURLs.getAccountDropdownList
    return this.http.get(apiurl, this.header);
  }

  public getAccountDetails(accountId, type, status): Observable<any> {
    this.setAccessToken();
    this.setHttpHeader();
    const apiurl = environment.appUrl 
    +customerAPIURLs.getAccountDetails
    +accountId
    +"&type="+type
    +"&status="+status;
    return this.http.get(apiurl, this.header);
  }
  
  public accountSelectorData(accId: string): void {
    this.accountSelector.next(accId);
  }
}
