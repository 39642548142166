import { ChangeDetectionStrategy, Inject, Component, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';
import { AppUtils } from 'projects/dialog/src/app/shared/utilities/app-utils';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dl-customer-contact-details',
  templateUrl: './customer-contact-details.component.html',
  styleUrls: ['./customer-contact-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerContactDetailsComponent implements OnInit, AfterViewInit {
  public countryList = []; /*[
    {
      label: 'United Kingdom',
      value: 'GB',
      disabled: false
    },
    {
      label: 'United States of America',
      value: 'US',
      disabled: false
    }
  ];*/
  public contactDataForm: FormGroup;
  public feedbackSubmittedData;
  public notificationData;
  public constructor(public _changeDetect: ChangeDetectorRef, public renderer: Renderer2, public fb: FormBuilder, public appUtil: AppUtils, public router: Router, public customerservice: CustomerNotificationServiceService, public activateroute: ActivatedRoute, public readonly oneSnackBar?: OneSnackBarService) {
    // this.renderer.addClass(document.body, 'local-notification-config');
    this.feedbackSubmittedData = history.state.feedbackData;
    this.notificationData = history.state.notificationData;
  }

  public ngOnInit(): void {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ .]{2,30}$/;
    const middleNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ .]{1,30}$/;
    this.contactDataForm = this.fb.group({
      fcCountry: ['', Validators.required],
      fcEmail: [null, [Validators.required, Validators.pattern(emailRegex)]], // Validators.email
      fcFirstName: [null, [Validators.required, Validators.pattern(nameRegex)]],
      fcMiddleName: [null, Validators.pattern(middleNameRegex)],
      fcLastName: [null, [Validators.required, Validators.pattern(nameRegex)]]
    });

    this.countryList = this.customerservice.getAffiliateCountries();
  }
  
  public ngAfterViewInit(): void {
    const localId = this.notificationData ? this.notificationData.LocalId : '';
    this.customerservice.setLocalId(localId);
  }

  public Oncancel(): void {
    const urlData = sessionStorage.getItem('urlParameters');
    const url = `/customer-notification-details?${urlData}`;
    this.router.navigateByUrl(url);
  }
  public SubmitFeedback(): void {
    sessionStorage.removeItem('urlParameters');
    const contactsData = this.contactDataForm.value;
    const middleName = contactsData.fcMiddleName ? contactsData.fcMiddleName.trim() : '';
    const contactDetailsObj = {
      CustomerAddedCountry: contactsData.fcCountry.trim(),
      CustomerAddedEmail: contactsData.fcEmail.trim(),
      CustomerAddedFirstName: contactsData.fcFirstName.trim(),
      CustomerAddedMiddleName: middleName,
      CustomerAddedLastName: contactsData.fcLastName.trim()
    };
    const feedbackResponse = {
      ...this.feedbackSubmittedData,
      ...contactDetailsObj
    };
    // this.router.navigateByUrl('/welcome', { state: { notificationData: this.notificationData } });
    this.customerservice.putService(feedbackResponse).subscribe(
      (response: HttpResponse<any>) => {
        this.customerservice.storeNotificationTableData = null;
        const feedbackSubmitted = this.appUtil.getTranslatedMessage('FeedbackSubmitted');
        this.oneSnackBar.open(feedbackSubmitted, OneSnackBarType.SUCCESS, '', { duration: 3000 });
        const _self = this;
        setTimeout(function(){
          _self.router.navigateByUrl('/welcome', { state: { notificationData: this.notificationData } });
        }, 5000);
      },
      (error: HttpErrorResponse) => {
        const feedbackFailed = this.appUtil.getTranslatedMessage('FeedbackFailed');
        this.oneSnackBar.open(feedbackFailed, OneSnackBarType.ERROR, '', { duration: 3000 });
      }
    );
  }
}
