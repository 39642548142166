import { ChangeDetectionStrategy, Component, Renderer2, ElementRef, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { RememberLocationService } from '@dialog-eservices-enablement/angular-components';
import { DetermineBundleService } from '@dialog-eservices-enablement/angular-components';
import { ChangeBundleService } from '@dialog-eservices-enablement/angular-components';
import { AffiliateService } from '@dialog-eservices-enablement/angular-components';
import { AvailabilitiesService } from '@dialog-eservices-enablement/angular-components';
import { UserEServicesService } from '@dialog-eservices-enablement/angular-components';
import { LearnMoreDialogService } from '@dialog-eservices-enablement/angular-components';
import { AnalyticsService } from './core/services/analytics.service';
import { View } from './shared/models/view';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { RegionDialogService } from './core/services/region-dialog.service';
import { CustomerNotificationServiceService } from './features/services/customer-notification-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'dl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('eserviceAvailabilityError') private eServicesAvailabilityMessage: ElementRef;
  @ViewChild('userEservicesError') private userEservicesMessage: ElementRef;
  @ViewChild('noLearnMoreContent') private learnMoreMessage: ElementRef;
  @ViewChild('requestSuccess') private requestSuccessMessage: ElementRef;
  @ViewChild('requestFail') private requestFailureMessage: ElementRef;
  @ViewChild('liveChatError') private liveChatErrorMessage: ElementRef;
  @ViewChild('favoriteAdded') private favoriteAddedMessage: ElementRef;
  @ViewChild('favoriteRemoved') private favoriteRemovedMessage: ElementRef;
  @ViewChild('favoriteError') private favoriteErrorMessage: ElementRef;
  @ViewChild('changeGroupsError') private changeGroupsError: ElementRef;
  @ViewChild('changeGroupsSuccess') private changeGroupsSuccess: ElementRef;
  @ViewChild('createGroupError') private createGroupError: ElementRef;
  @ViewChild('createGroupSuccess') private createGroupSuccess: ElementRef;
  @ViewChild('deleteGroupError') private deleteGroupError: ElementRef;
  @ViewChild('deleteGroupSuccess') private deleteGroupSuccess: ElementRef;
  @ViewChild('editGroupError') private editGroupError: ElementRef;
  @ViewChild('editGroupSuccess') private editGroupSuccess: ElementRef;
  @ViewChild('getGroupsError') private getGroupsError: ElementRef;
  @ViewChild('getHelpTicketSuccess') private getHelpTicketSuccess: ElementRef;
  @ViewChild('getHelpTicketRequestTypeTechnicalIssue') private getHelpTicketRequestTypeTechnicalIssue: ElementRef;
  @ViewChild('getHelpTicketRequestTypeFeatureRequest') private getHelpTicketRequestTypeFeatureRequest: ElementRef;
  @ViewChild('getHelpTicketRequestTypeOther') private getHelpTicketRequestTypeOther: ElementRef;
  
  public isLoggedIn = this.authService.isLoggedIn;
  public urlQueryParameter = sessionStorage.getItem('urlParameters');
  public userType = localStorage.getItem('userType');
  public applicationLocale = localStorage.getItem('rememberMyLocation');
  public View = View;
  public view$: Observable<View> = this.breakpointObserver.observe('(min-width: 600px)').pipe(
    map((result: BreakpointState) => result.matches),
    map((isDesktop: boolean) => {
      if (!isDesktop) {
        return View.Mobile;
      } else {
        if (this.authService.isLoggedIn && this.userType !== 'isNonDialogUser') {
          return View.DesktopLoggedIn;
        } else {
          return View.DesktopLoggedOut;
        }
      }
    })
  );
  public selectFormControl = new FormControl();
  public dialogUserFlag:boolean = false;
  public selectOptList:any = [{
    label: 'My notifications',
    value: 'My notifications',
    title: 'My notifications'
  }];
  public isDDVisible: boolean = true;

  public constructor(
    public renderer: Renderer2, 
    private router: Router, 
    private affiliateService: AffiliateService, 
    private rememberLocationService: RememberLocationService, 
    private customerservice: CustomerNotificationServiceService, 
    private analyticsService: AnalyticsService, 
    private breakpointObserver: BreakpointObserver, 
    private authService: AuthService, 
    private availabilitiesService: AvailabilitiesService, 
    private userEservices: UserEServicesService, 
    private learnMoreService: LearnMoreDialogService, 
    private regionDialogService: RegionDialogService, 
    private determineBundleService: DetermineBundleService, 
    private changeBundleService: ChangeBundleService
  ){}

  public ngOnInit(): void {
    
    this.analyticsService.init();
    this.getDefaultCountryLanguage();
    if (this.authService.isLoggedIn && this.userType !== 'isNonDialogUser') {
      localStorage.setItem('userType', 'isLoginDialogUser');
      this.userType = localStorage.getItem('userType');
      this.dialogUserFlag = true;
      this.customerservice.getAccountDropdownList().subscribe(
        res => {
          this.selectOptList.push(
            ...res.body.response.content.accounts.map( acc => ({label: acc.account_name, value: acc.crmNumber, title: `${acc.crmNumber} (${acc.erpNumber})`}) )
          );
          this.selectFormControl.setValue(this.selectOptList[0].value);
        }
      );
    } else if (!this.authService.isLoggedIn && this.userType !== 'isNonDialogUser') {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
      this.userType = localStorage.getItem('userType');
    }
    // date: 02/03/2023 removed condition "this.authService.canOpenLoginDialog() &&" from if condition to resolve login session issue
    if (this.authService.canOpenLoginDialog() && this.userType === 'isNotLoginDialogUser') {
      this.renderer.removeClass(document.body, 'location-modal-view');
      this.renderer.addClass(document.body, 'login-modal-view');
      this.authService.openLoginDialog();
    } else {
      this.renderer.removeClass(document.body, 'login-modal-view');
    }
    if ((this.userType === 'isLoginDialogUser' && this.urlQueryParameter) || this.userType === 'isNonDialogUser') {
      this.applicationLocale = localStorage.getItem('rememberMyLocation');
      this.router.navigate(['/customer-notification-details']);
    }
    this.customerservice.isDDVisible.subscribe(
      res => {
        this.isDDVisible = res
      }
    )
  }

  public getDefaultCountryLanguage(): void {
    // added for deep linking url
    const urlQueryParameter = sessionStorage.getItem('urlParameters');
    const country = this.customerservice.getUrlVar(this.urlQueryParameter, 'country') || '';

    if (country) {
      const affiliateData = this.affiliateService.affiliates;
      const notificationCountry = country.toLowerCase();
      const chosenAffiliate = affiliateData.find(affilate => affilate.affiliate.toLowerCase() === notificationCountry);
      // date: 02/03/2023 changed to "chosenAffiliate.defaultLanguage" from "chosenAffiliate.defaultLocale" to resolve incident-INC8561884
      const defaultLanguage = chosenAffiliate ? chosenAffiliate.defaultLanguage : 'en_US';
      const countryCode = chosenAffiliate ? notificationCountry : 'us';
      if (notificationCountry && defaultLanguage) {
        const appLocation = {
          countryCode: countryCode,
          languageCode: defaultLanguage
        };
        this.rememberLocationService.rememberLocation(appLocation);
        if (!this.determineBundleService.isDefaultBundle) {
          return;
        } else {
          this.changeBundleService.redirectToTranslatedBundle(appLocation);
        }
      }
    } else {
      this.urlDeepLinking();
    }
  }

  public urlDeepLinking(): void {
    const pathArray = window.location.pathname.split('/');
    const urlCountry = pathArray[2];
    const urlLanguage = pathArray[3];
    const urlSlug = pathArray[4];

    if (urlCountry && urlLanguage) {
      const appLocation = {
        countryCode: urlCountry,
        languageCode: urlLanguage
      };
      this.rememberLocationService.rememberLocation(appLocation);
      if (!this.determineBundleService.isDefaultBundle) {
        return;
      } else {
        this.changeBundleService.redirectToTranslatedBundle(appLocation);
      }
    } else {
      if (!this.applicationLocale) {
        this.renderer.addClass(document.body, 'location-modal-view');
        this.regionDialogService.openDialog(true);
      }
    }
  }

  public ngAfterViewInit(): void {
    this.availabilitiesService.eServiceAvailabilityError = this.eServicesAvailabilityMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.userEservicesError = this.userEservicesMessage.nativeElement.previousSibling.wholeText;
    this.learnMoreService.learnMoreErrorText = this.learnMoreMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.requestAccessSuccessMessage = this.requestSuccessMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.requestAccessErrorMessage = this.requestFailureMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteAddedMessage = this.favoriteAddedMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteRemovedMessage = this.favoriteRemovedMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.favoriteErrorMessage = this.favoriteErrorMessage.nativeElement.previousSibling.wholeText;
  }

  public getAccountDetails(accountId){
    this.customerservice.accountSelectorData(accountId);
  }
}
