import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationFilterComponent } from './notification-filter/notification-filter.component';
import { NotificationTableComponent } from './notification-table/notification-table.component';
import { NotificationSearchComponent } from './notification-search/notification-search.component';
import { MatCardModule } from '@angular/material/card';
import { OneFilteringChipModule } from '@one/angular-kit/indicator';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OneFormFieldModule } from '@one/angular-kit/form';
import { OneSelectDropdownModule } from '@one/angular-kit/form';
import { MatSelectModule } from '@angular/material/select';
import { OneIconModule } from '@one/angular-kit/icon';
import { OneTableModule } from '@one/angular-kit/data-table';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { OneChipModule } from '@one/angular-kit/indicator';
import { AppUtils } from '../../shared/utilities/app-utils';
import { OneDialogModule, OneSnackBarModule } from '@one/angular-kit/modal';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRouterModule } from '../../app-router.module';

import { OneBreadcrumbModule } from '@one/angular-kit/navigation';
import { MatListModule } from '@angular/material/list';
import { OneListModule, OneExpandableModule } from '@one/angular-kit/layout';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { OneMenuModule } from '@one/angular-kit/navigation';
import { CustomerNotificationComponent } from './customer-notification.component';
import { CustomerNotificationDetailsComponent } from './customer-notification-details/customer-notification-details.component';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CustomerContactDetailsComponent } from './customer-contact-details/customer-contact-details.component';
import { NondialogCustomerWelcomeComponent } from './nondialog-customer-welcome/nondialog-customer-welcome.component';
import { QnsbnHeaderLogoComponent } from './qnsbn-header-logo/qnsbn-header-logo.component';
@NgModule({
  declarations: [CustomerNotificationComponent, NotificationSearchComponent, NotificationTableComponent, NotificationFilterComponent, CustomerNotificationDetailsComponent, CustomerContactDetailsComponent, NondialogCustomerWelcomeComponent, QnsbnHeaderLogoComponent],
  exports: [CustomerNotificationComponent],
  imports: [AppRouterModule, CommonModule, MatCardModule, MatIconModule, MatSelectModule, OneSelectDropdownModule, FormsModule, MatToolbarModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, OneFilteringChipModule, OneFormFieldModule, OneIconModule, OneTableModule, MatTableModule, CdkTableModule, MatCheckboxModule, OneTopbarModule, MatSortModule, MatButtonModule, MatSidenavModule, MatChipsModule, OneChipModule, OneDialogModule, OneSnackBarModule, MatRadioModule, MatDialogModule, OneBreadcrumbModule, MatListModule, OneListModule, OneExpandableModule, RouterModule, MatMenuModule, OneMenuModule],
  providers: [AppUtils]
})
export class CustomerNotificationModule {}
