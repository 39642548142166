import { Inject, Injectable } from '@angular/core';
import { CountryLanguageService } from '@dialog-eservices-enablement/angular-components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { AffiliateService } from '@dialog-eservices-enablement/angular-components';
import { RememberLocationService } from '@dialog-eservices-enablement/angular-components';
import { DetermineBundleService } from '@dialog-eservices-enablement/angular-components';
import { ChangeBundleService } from '@dialog-eservices-enablement/angular-components';
import { RegionDialogComponent, RegionDialogInput, RegionDialogOutput } from '../components/region-dialog/region-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RegionDialogService {
  public constructor(private countryLanguageService: CountryLanguageService, private rememberLocationService: RememberLocationService, private affiliateService: AffiliateService, private dialog: MatDialog, @Inject(ENVIRONMENT) private environment: EnvironmentInterface, private determineBundleService: DetermineBundleService, private changeBundleService: ChangeBundleService) {}

  public openDialog(disableClose?: boolean): Promise<MatDialogRef<RegionDialogComponent>> {
    if (!this.determineBundleService.isDefaultBundle) {
      return;
    }
    return new Promise<MatDialogRef<RegionDialogComponent>>(resolve => {
      const input: RegionDialogInput = {
        affiliates: this.affiliateService.affiliates,
        // null, because we show the region dialog on the default bundle only and on the default bundle there is no selected affiliate
        preSelectedAffiliate: null
      };
      const dialogRef = this.dialog.open(RegionDialogComponent, { data: input, disableClose, autoFocus: false, position: { top: '144px' } });

      dialogRef.afterClosed().subscribe((output: RegionDialogOutput) => {
        if (!output) {
          // There is no output, when the user closes the dialog without making a selection
          return;
        }
        const location = {
          countryCode: output.selectedAffiliate.affiliate.toLowerCase(),
          languageCode: output.selectedAffiliate.defaultLanguage.toLowerCase()
        };
        if (output.rememberMyLocation) {
          this.rememberLocationService.rememberLocation(location);
        }
        this.changeBundleService.redirectToTranslatedBundle(location);
      });
      resolve(dialogRef);
    });
  }
}
