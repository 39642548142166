import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { DetermineBundleService, UserProfile } from '@dialog-eservices-enablement/angular-components';
import { AuthApiService } from '@dialog-eservices-enablement/angular-components';
import { RememberLocationService } from '@dialog-eservices-enablement/angular-components';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { SupplementalContentService } from '@dialog-eservices-enablement/angular-components';
import { forkJoin } from 'rxjs';
import { AffiliateService } from '@dialog-eservices-enablement/angular-components';
import { ChangeBundleService } from '@dialog-eservices-enablement/angular-components';
import { SessionStorageServiceReusableComponents } from '@dialog-eservices-enablement/angular-components';
import { ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { HttpClient } from '@angular/common/http';
import { CustomerNotificationServiceService } from '../../features/services/customer-notification-service.service';
import { OneSnackBarService } from '@one/angular-kit/modal';

type Session = {
  loggedIn: boolean;
  identifier?: string;
};

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  public constructor(private customerservice: CustomerNotificationServiceService, private localStorageService: SessionStorageServiceReusableComponents, private determineBundleService: DetermineBundleService, private changeBundleService: ChangeBundleService, private rememberLocationService: RememberLocationService, private authApiService: AuthApiService, private authService: AuthService, private supplementalContentService: SupplementalContentService, private affiliateService: AffiliateService, @Inject(ENVIRONMENT) private environment: EnvironmentInterface, private http: HttpClient, public readonly oneSnackBar: OneSnackBarService) {}

  public initApp(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authService.init()
        .then(userProfile => this.checkCurrentUser(userProfile).then(resolve).catch(reject))
        .catch(reject);
    });
  }

  // This method should be managed by authServie from angular-components.
  // Until is implemented there, we will keep it here.
  private checkCurrentUser(user: UserProfile): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.environment.featureFlags.apiCallSSO && user) {
        this.http
          .jsonp(`https://${this.environment.dialogUrl}/DLG_Access_IsLoggedIn?callback=JSONP_CALLBACK`, 'success')
          .toPromise()
          .then(
            (jsonpResponse: Session) => {
                if (!jsonpResponse.loggedIn ||
                  jsonpResponse.identifier != user.trackingId) {
                  this.authApiService.signOut();
                  reject();
                }
                this.startBundle(user).then(resolve).catch(reject);
              })
            .catch((e) => {
              this.startBundle(user).then(resolve).catch(reject);
            }
          );
      }
      else {
        this.startBundle(user).then(resolve).catch(reject);
      }
    });
  }


  private startBundle(userProfile: UserProfile): Promise<void> {
    if (this.determineBundleService.isDefaultBundle) {
      return this.startDefaultBundle(userProfile);
    }
    else {
      return this.startTranslatedBundle();
    }
  }

  private startDefaultBundle(userProfile: UserProfile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      
      // 03-22-23 Added to override location when lunch via direct link url
      const parameters = new URLSearchParams(window.location.search);
      if (parameters.get('publicKeyToken')) {
        localStorage.removeItem('rememberMyLocation');
      }

      // If we know the user, redirect to his bundle
      const isLoggedIn = Boolean(userProfile);
      if (isLoggedIn) {
        const location = {
          countryCode: userProfile.countryCode,
          languageCode: userProfile.language
        };
        this.changeBundleService.redirectToTranslatedBundle(location)
          .then(reject)
          .catch(() => this.loadPlatformInformation(resolve, reject));
        return;
      }

      // If we know this location, redirect to that bundle
      const isLocationRemembered = this.rememberLocationService.isLocationRemembered();
      if (isLocationRemembered) {
        const { countryCode, languageCode } = this.rememberLocationService.getRememberedLocation();
        this.changeBundleService.redirectToTranslatedBundle({ countryCode, languageCode })
          .then(reject)
          .catch(() => {
             this.rememberLocationService.forgetLocation();
             this.loadPlatformInformation(resolve, reject);
          });
        return;
      }      

      this.loadPlatformInformation(resolve, reject);
    });
  }

  private startTranslatedBundle(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      
      // Remember location
      const pathArray = window.location.pathname.split('/');
      const urlCountry = pathArray[2];
      const urlLanguage = pathArray[3];
      if (urlCountry && urlLanguage) {
        const location = {
          countryCode: urlCountry,
          languageCode: urlLanguage
        };
        this.rememberLocationService.rememberLocation(location);
      }

      this.loadPlatformInformation(
        () => {
          // Show the OneTrust banner, which is created with display: none; by the script
          const css = '#onetrust-consent-sdk {display: block !important}';
          const head = document.head || document.getElementsByTagName('head')[0];
          const style = document.createElement('style');
          head.appendChild(style);
          style.type = 'text/css';
          style.appendChild(document.createTextNode(css));
          resolve();
        }, 
        reject);
    });
  }

  private loadPlatformInformation(resolve, reject) : Promise<void> {
    return new Promise<void>(() => {
      forkJoin([this.supplementalContentService.setSupplementalContent(), this.affiliateService.setAffiliates()]).subscribe(
        () => resolve(), 
        () => reject()
      );
    });
  }  
}


export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initApp(),
  deps: [AppInitializerService]
};
