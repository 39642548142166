import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationFilterService } from './notification-filter.service';
import { Subscription } from 'rxjs';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';
import { es } from 'date-fns/locale';

export interface FilterOptions {
  typeFilters: string[];
  statusFilters: string[];
  searchText: string;
}
@Component({
  selector: 'dl-notification-filter',
  templateUrl: './notification-filter.component.html',
  styleUrls: ['./notification-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationFilterComponent implements OnInit, OnDestroy {
  public width = '240px';
  public widthStatus = '240px';
  public formDisabled = false;

  public typeFormControl = new FormControl();
  public typeList = [];
  public typeListLocale = [
    { label: $localize `:@@notification.type.list1:QN`, value: "QN"},
    { label: $localize `:@@notification.type.list2:SBN`, value: "SBN"}
  ];

  public typePlaceholder = $localize `:Type|Notification type filter@@notifications.list.type-filter.text:Type`;
  public statusPlaceholder = $localize `:Status|Notification status filter@@notifications.list.status-filter.text:Status`;
  public statusFormControl = new FormControl();
  public statusList = [];
  public statusListLocale = [
    { label: $localize `:@@notification.status.list1:Feedback Submitted`, value: "Feedback Submitted"},
    { label: $localize `:@@notification.status.list2:Feedback Overdue`, value: "Feedback Overdue"}, 
    { label: $localize `:@@notification.status.list3:Reminder`, value: "Reminder"}, 
    { label: $localize `:@@notification.status.list4:Read`, value: "Read"},
    { label: $localize `:@@notification.status.list5:New`, value: "New"},
    { label: $localize `:@@notification.status.list6:Completed`, value: "Completed"},
    { label: "Feedback Received", value: "Feedback Received"},
    { label: "Send", value: "Send"},
    { label: "Delivered", value: "Delivered"}
  ];

  public typeFilters: [];
  public statusFilters: [];
  public filterDataObject: FilterOptions;

  private _typeSubscription: Subscription | undefined;
  private _statusSubscription: Subscription | undefined;
  private _typeFilterSubscription: Subscription | undefined;
  private _statusFilterSubscription: Subscription | undefined;
  public isClearbtnVisi: boolean = false;  

  public accSelected:string;
  private _accSelectorSubscription: Subscription | undefined;
  public accTypeList = [];
  public accStatusList = [];

  public constructor(private notificationFilterService: NotificationFilterService, public customerservice: CustomerNotificationServiceService) {
    this.filterDataObject = { typeFilters: [], statusFilters: [], searchText: '' };
  }

  public ngOnInit(): void {

    this._accSelectorSubscription = this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelected = res;
      }
    );

    this._typeSubscription = this.typeFormControl.valueChanges.subscribe(val => {
      if (val && val.length > 0) {
        this.typeFilters = val.map(item => item.value.trim());
        this.filterDataObject.typeFilters = this.typeFilters;
        this.notificationFilterService.getfilteredData(this.filterDataObject);
        this.isClearbtnVisi = true;
      } else {
        this.filterDataObject.typeFilters = [];
        this.notificationFilterService.getfilteredData(this.filterDataObject);
        this.isClearbtnVisi = false;
      }
    });

    this._statusSubscription = this.statusFormControl.valueChanges.subscribe(val => {
      if (val && val.length > 0) {
        this.statusFilters = val.map(item => item.value.trim());
        this.filterDataObject.statusFilters = this.statusFilters;
        this.notificationFilterService.getfilteredData(this.filterDataObject);
        this.isClearbtnVisi = true;
      } else {
        this.filterDataObject.statusFilters = [];
        this.notificationFilterService.getfilteredData(this.filterDataObject);
        this.isClearbtnVisi = false;
      }
    });

    this._typeFilterSubscription = this.notificationFilterService.typeFilterobservable$.subscribe(
      ele => {
        if(this.accSelected === 'My notifications'){
          if (ele.label !== '' && ele.label !== undefined) {
            let checkEle = true;
            this.typeList.some(
              (el, ind) => {
                if (el.label.trim().toLowerCase() === ele.label.trim().toLowerCase()) checkEle = false;
              }
            );
            if (checkEle) this.typeList.push(ele);
          }
        }else{
          if (ele.label !== '' && ele.label !== undefined) {
            let checkEle = true;
            this.accTypeList.some(
              (el, ind) => {
                if (el.label.trim().toLowerCase() === ele.label.trim().toLowerCase()) checkEle = false;
              }
            );
            if (checkEle) this.accTypeList.push(ele);
          }
        }
        return;
      }
    );


    this._statusFilterSubscription = this.notificationFilterService.statusFilterobservable$.subscribe(
      ele => {
        if(this.accSelected === 'My notifications'){
          if (ele.value !== '' && ele.value !== undefined) {
            let checkEle = true;
            this.statusList.some(
              (el, ind) => {
                if (el.value.trim().toLowerCase() === ele.value.trim().toLowerCase()) checkEle = false;
              }
            );
            if (checkEle) {
              let itemToAdd = this.statusListLocale.find(item => {
                return item.value.trim() === ele.value.trim();
              });
              this.statusList.push(itemToAdd);
            }
          }
        }else{
          if (ele.value !== '' && ele.value !== undefined) {
            let checkEle = true;
            this.accStatusList.some(
              (el, ind) => {
                if (el.value.trim().toLowerCase() === ele.value.trim().toLowerCase()) checkEle = false;
              }
            );
            if (checkEle) {
              let itemToAdd = this.statusListLocale.find(item => {
                return item.value.trim() === ele.value.trim();
              });
              this.accStatusList.push(itemToAdd);
            }
          }
        }
        return;
      }
    );

  }

  public clear(){
    this.typeFormControl.patchValue('');
    this.statusFormControl.patchValue('');
    this.isClearbtnVisi = false;
  }

  public ngOnDestroy(): void {
    this._typeSubscription?.unsubscribe();
    this._statusSubscription?.unsubscribe();
    this._typeFilterSubscription?.unsubscribe();
    this._statusFilterSubscription?.unsubscribe();
    this._accSelectorSubscription?.unsubscribe();
  }

}
