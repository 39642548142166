export class AppUtils {
  public constructor() {}
  public mimeType = 'application/pdf';
  public fetchDoc(apiURL: string, apikey?: string, accessToken?: string, fileName?: string): void {
    let options = {
      method: 'GET',
      headers: {}
    };
    if (accessToken) {
      options = {
        method: 'GET',
        headers: {
          'x-api-key': apikey,
          Authorization: accessToken
        }
      };
    }
    let isDownloadable = false;
    if (fileName) {
      this.mimeType = this.getMimeType(fileName);
      const ext = fileName ? fileName.split('.').pop() : '';
      const downloadableFile = ['csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'zip', 'tar', 'rar', 'gzip', 'txt'];
      isDownloadable = downloadableFile.indexOf(ext) >= 0;
    }
    const self = this;
    fetch(apiURL, options)
      .then(handleErrors)
      .then(r => r.blob())
      .then(blob => {
        // this.showFile
        const newBlob = new Blob([blob], { type: self.mimeType }); // 'application/pdf'
        const data = window.URL.createObjectURL(newBlob);
        // window.open(data, '_blank')
        const link = document.createElement('a');
        link.href = data;
        link.target = '_blank';
        if (isDownloadable) {
          link.download = fileName;
        }
        link.click();
        // commented on 30/01/2023 because BLOB pdf downloading was having issue
        // setTimeout(() => {
        //   // For Firefox it is necessary to delay revoking the ObjectURL
        //   window.URL.revokeObjectURL(data);
        // }, 100);
      })
      .catch(error =>{
        // console.log('ERROR: ' + error)
      });

    function handleErrors(response: Response): Response {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    }
  }
  public showFile(blob: any): void {
    const newBlob = new Blob([blob], { type: blob.type }); // 'application/pdf'
    const data = window.URL.createObjectURL(newBlob);
    window.open(data, '_blank');
  }

  public formatFilesIcons(filename: string): string {
    const ext = filename ? filename.split('.').pop() : '';
    let fileIcon = 'file_pdf';
    switch (ext) {
      case 'apng':
      case 'bmp':
      case 'gif':
      case 'pjpeg':
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'PNG':
        fileIcon = 'image';
        break;
      case 'csv':
      case 'xls':
      case 'xlsx':
        fileIcon = 'file_excel';
        break;
      case 'doc':
      case 'docx':
        fileIcon = 'file_word';
        break;
      case 'pdf':
        fileIcon = 'file_pdf';
        break;
      case 'ppt':
      case 'pptx':
        fileIcon = 'document';
        break;
      case 'zip':
      case 'tar':
      case 'rar':
      case 'gzip':
        fileIcon = 'file_folder';
        break;
      case 'txt':
        fileIcon = 'document'; // icon is not available , temp document used
        break;
      default:
        fileIcon = 'document'; // icon is not available , temp document used
        break;
    }
    return fileIcon;
  }
  public getMimeType(fileName: string): string {
    const tokens = fileName.split('.').pop();
    const fileExtension = '.' + tokens;
    switch (fileExtension) {
      case '.aac':
        return 'audio/aac';
        break;
      case '.abw':
        return 'application/x-abiword';
        break;
      case '.arc':
        return 'application/x-freearc';
        break;
      case '.avi':
        return 'video/x-msvideo';
        break;
      case '.azw':
        return 'application/vnd.amazon.ebook';
        break;
      case '.bin':
        return 'application/octet-stream';
        break;
      case '.bmp':
        return 'image/bmp';
        break;
      case '.bz':
        return 'application/x-bzip';
        break;
      case '.bz2':
        return 'application/x-bzip2';
        break;
      case '.cda':
        return 'application/x-cdf';
        break;
      case '.csh':
        return 'application/x-csh';
        break;
      case '.css':
        return 'text/css';
        break;
      case '.csv':
        return 'text/csv';
        break;
      case '.doc':
        return 'application/msword';
        break;
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case '.eot':
        return 'application/vnd.ms-fontobject';
        break;
      case '.epub':
        return 'application/epub+zip';
        break;
      case '.gz':
        return 'application/gzip';
        break;
      case '.gif':
        return 'image/gif';
        break;
      case '.htm':
        return 'text/html';
        break;
      case '.ico':
        return 'image/vnd.microsoft.icon';
        break;
      case '.ics':
        return 'text/calendar';
        break;
      case '.jar':
        return 'application/java-archive';
        break;
      case '.jpeg':
        return 'image/jpeg';
        break;
      case '.js':
        return 'text/javascript';
        break;
      case '.json':
        return 'application/json';
        break;
      case '.jsonld':
        return 'application/ld+json';
        break;
      case '.mid':
        return 'audio/midi audio/x-midi';
        break;
      case '.midi':
        return 'audio/midi audio/x-midi';
        break;
      case '.mjs':
        return 'text/javascript';
        break;
      case '.mp3':
        return 'audio/mpeg';
        break;
      case '.mp4':
        return 'video/mp4';
        break;
      case '.mpeg':
        return 'video/mpeg';
        break;
      case '.mpkg':
        return 'application/vnd.apple.installer+xml';
        break;
      case '.odp':
        return 'application/vnd.oasis.opendocument.presentation';
        break;
      case '.ods':
        return 'application/vnd.oasis.opendocument.spreadsheet';
        break;
      case '.odt':
        return 'application/vnd.oasis.opendocument.text';
        break;
      case '.oga':
        return 'audio/ogg';
        break;
      case '.ogv':
        return 'video/ogg';
        break;
      case '.ogx':
        return 'application/ogg';
        break;
      case '.opus':
        return 'audio/opus';
        break;
      case '.otf':
        return 'font/otf';
        break;
      case '.png':
        return 'image/png';
        break;
      case '.pdf':
        return 'application/pdf';
        break;
      case '.php':
        return 'application/x-httpd-php';
        break;
      case '.ppt':
        return 'application/vnd.ms-powerpoint';
        break;
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      case '.rar':
        return 'application/vnd.rar';
        break;
      case '.rtf':
        return 'application/rtf';
        break;
      case '.sh':
        return 'application/x-sh';
        break;
      case '.svg':
        return 'image/svg+xml';
        break;
      case '.swf':
        return 'application/x-shockwave-flash';
        break;
      case '.tar':
        return 'application/x-tar';
        break;
      case '.tif':
        return 'image/tiff';
        break;
      case '.tiff':
        return 'image/tiff';
        break;
      case '.ts':
        return 'video/mp2t';
        break;
      case '.ttf':
        return 'font/ttf';
        break;
      case '.txt':
        return 'text/plain';
        break;
      case '.vsd':
        return 'application/vnd.visio';
        break;
      case '.wav':
        return 'audio/wav';
        break;
      case '.weba':
        return 'audio/webm';
        break;
      case '.webm':
        return 'video/webm';
        break;
      case '.webp':
        return 'image/webp';
        break;
      case '.woff':
        return 'font/woff';
        break;
      case '.woff2':
        return 'font/woff2';
        break;
      case '.xhtml':
        return 'application/xhtml+xml';
        break;
      case '.xls':
        return 'application/vnd.ms-excel';
        break;
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case '.xml':
        return 'application/xml';
        break;
      case '.xul':
        return 'application/vnd.mozilla.xul+xml';
        break;
      case '.zip':
        return 'application/zip';
        break;
      case '.3gp':
        return 'video/3gpp';
        break;
      case '.3g2':
        return 'video/3gpp2';
        break;
      case '.7z':
        return 'application/x-7z-compressed';
        break;
      default:
        return 'application/octet-stream';
    }
  }
  public getLowerCase(data: any): string {
    const lowercaseData = data ? data.toString().toLowerCase() : '';
    return lowercaseData;
  }
  // format date to dd-mmm-yyyy (07-Jan-2021)
  public formatDateOnly(dateValue: Date): string {
    if (dateValue) {
      const date = new Date(dateValue);
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: 'numeric' });
      return day + '-' + month + '-' + year;
    } else {
      return '';
    }
  }
  
  public getTranslatedMessage(errCode: string): string {
    switch (errCode) {
      case '401':
        return $localize `:@@notification.details.error401:Token Expire, Please login again.`;
      case '403':
        return $localize `:@@notification.details.error403:Invalid link. User is not authorized to access this information. Please contact your local Roche Product and Safety Care contact.`;
      case '404':
        return $localize `:@@notification.details.error404:Could not find notification. Please contact your local Roche Product and Safety Care contact. You will be redirect to overview page.`;
      case '203':
        return $localize `:@@notification.details.error203:You are unauthorized to access this service.`;
      case 'detailFailed':
        return $localize `:@@notification.details.errors:Could not fetch notification details. Please verify details. It will be redirect to overview page`;
      case 'FeedbackSubmitted':
        return $localize `:@@notification.feedback.submitted:Feedback submitted successfully.`;
      case 'FeedbackFailed':
        return $localize `:@@notification.feedback.failed:Sending FeedBack is Failed.`;
      case 'AccountFailed':
        return `Could not fetch account notifications. Please try again later`;
        // return $localize `:@@notification.feedback.failed:Sending FeedBack is Failed.`;
    }
  }

  public getStatusText(ntStatus: string): string {
    switch (ntStatus) {
      case 'New':
        return $localize `:@@notification.status.list5:New`;
      case 'Read':
        return $localize `:@@notification.status.list4:Read`;
      case 'Completed':
        return $localize `:@@notification.status.list6:Completed`;
      case 'Feedback Overdue':
        return $localize `:@@notification.status.list2:Feedback Overdue`;
      case 'Feedback Submitted':
        return $localize `:@@notification.status.list1:Feedback Submitted`;
      case 'Reminder':
        return $localize `:@@notification.status.list3:Reminder`;
      case 'Feedback Received':
        return `Feedback Received`;
        // return $localize `:@@notification.status.list3:Reminder`;
      default:
        return ntStatus;
    }
  }

  public getColor(ntStatus: string): string {
    switch (ntStatus) {
      case 'New':
        return 'New';
      case 'Read':
        return 'Read';
      case 'Completed':
        return 'Completed';
      case 'Feedback Overdue':
        return 'Feedback-Overdue';
      case 'Feedback Submitted':
        return 'Feedback-Submitted';
      case 'Feedback Received':
        return 'Feedback-Submitted';
      case 'Reminder':
        return 'Reminder';
      default:
        return 'Feedback-Submitted';
    }
  }

}
