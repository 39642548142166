import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationSearchService } from '../notification-search/notification-search.service';
import { NotificationFilterService } from '../notification-filter/notification-filter.service';
import { filter, debounceTime } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FilterOptions } from '../notification-filter/notification-filter.component';
import { Router } from '@angular/router';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';
import { AppUtils } from '../../../shared/utilities/app-utils';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dl-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotificationTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  public tableData = [];
  public columnData: any;
  public displayedColumns = ['Type', 'LocalId', 'Title','customerName', 'CreationDate', 'DeadlineFeedback', 'CustomerStatus'];
  public dataSource = new MatTableDataSource<any>();
  public selection = new SelectionModel(true, []);
  public filterArray: any[] = [];
  public initPageLoad = true;
  private _accDataSubscription: Subscription | undefined;
  private _accSelectorSubscription: Subscription | undefined;
  private _searchSubscription: Subscription | undefined;
  private _filterSubscription: Subscription | undefined;
  private _myNotificationSubscription: Subscription | undefined;
  public accSelected:string;

  public constructor(
    public appUtils: AppUtils, 
    public router: Router, 
    public changeDetect: ChangeDetectorRef, 
    public customerservice: CustomerNotificationServiceService, 
    public notificationFilterService: NotificationFilterService, 
    public notificationSearchService: NotificationSearchService,
    public readonly oneSnackBar?: OneSnackBarService
  ){}

  public ngOnInit(): void {
    this.sort.sort({ id: 'CreationDate', start: 'desc' } as MatSortable);
    this.dataSource.sort = this.sort;
    this.customerservice.isDDVisible.next(true);

    this._accSelectorSubscription = this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelected = res;
        if(res === 'My notifications'){
          this.getTableFilterData(['all'],['all']);
        }else{
          this.getAccFilterData(res,['all'],['all'])
        }
      }
    );

    this._searchSubscription = this.notificationSearchService.searchData$.subscribe(searchValue => {
      this.dataSource.filter = searchValue;
      this.dataSource.filterPredicate = (data: any, filterText: string) => {
        const filterString = filterText.toLowerCase();
        return this.appUtils.getLowerCase(data.Type).includes(filterString) 
        || this.appUtils.getLowerCase(data.LocalId).includes(filterString) 
        || this.appUtils.formatDateOnly(data.CreationDate).includes(filterString) 
        || this.appUtils.getLowerCase(data.Title).includes(filterString) 
        || this.appUtils.getLowerCase(data.CustomerStatus || data.Status).includes(filterString)
        || this.appUtils.getLowerCase(data.CustomerName).includes(filterString)
        || this.appUtils.formatDateOnly(data.FeedbackDueDate).includes(filterString)
        || this.appUtils.formatDateOnly(data.DateDelivered).includes(filterString);
      };
      this.initPageLoad = false;
      this.changeDetect.detectChanges();
    });

    this._filterSubscription = this.notificationFilterService.filterData$.pipe(debounceTime(500)).subscribe(filterValue => {
      if(filterValue.typeFilters.length <= 0 ){
        filterValue.typeFilters = ['all'];
      }
      if(filterValue.statusFilters.length <= 0 ){
        filterValue.statusFilters = ['all'];
      }
      if(this.accSelected === 'My notifications'){
        this.getTableFilterData(filterValue.typeFilters, filterValue.statusFilters);
      }else{
        this.getAccFilterData(this.accSelected, filterValue.typeFilters, filterValue.statusFilters)
      }
      this.initPageLoad = false;
      this.changeDetect.detectChanges();
    });
  }

  public getTableFilterData(type, status){
    this._myNotificationSubscription = this.customerservice.getNotificationTableData(type, status).subscribe(
      data => {
        this.tableData = data;
        this.dataSource.data = this.tableData;
        this.tableData.forEach(value => {
          this.notificationFilterService.setTypeName(value.Type);
          this.notificationFilterService.setStatusName(value.CustomerStatus || value.Status);
          this.notificationFilterService.setStatusName('Completed');
        });
        this.customerservice.storeNotificationTableData = this.tableData;
        if(this.tableData.length < 1) {
          this.initPageLoad = false;
        }
      },error => {
        // console.log('error', error);
      }
    );
  }

  public getAccFilterData(accSelected, type, status){
    this._accDataSubscription = this.customerservice.getAccountDetails(accSelected, type, status).subscribe(
      data => {
        this.tableData = data;
        this.dataSource.data = this.tableData;
        this.tableData.forEach(value => {
          this.notificationFilterService.setTypeName(value.Type);
          this.notificationFilterService.setStatusName(value.Status);
        });
        this.customerservice.storeNotificationTableData = this.tableData;
        if(this.tableData.length < 1) {
          this.initPageLoad = false;
        }
      },
      err => {
        const feedbackFailed = this.appUtils.getTranslatedMessage('AccountFailed');
        this.oneSnackBar.open(feedbackFailed, OneSnackBarType.ERROR, '', { duration: 3000 });
      }
    );
  }

  public filterSourceTable(filterValue: any): void {
    if (filterValue.typeFilters.length < 1 && filterValue.statusFilters.length < 1 && filterValue.searchText === '') {
      this.dataSource.data = this.tableData;
    } else {
      const typeFilterArray = this.tableData.filter(data => filterValue.typeFilters.includes(data.Type) || filterValue.typeFilters.length === 0);
      const statusFilterArray = typeFilterArray.filter(data => filterValue.statusFilters.includes(data.CustomerStatus || data.Status) || filterValue.statusFilters.length === 0);
      const searchString = filterValue.searchText;
      // this.dataSource.data = statusFilterArray.filter(data => data.LocalId.toLowerCase().includes(searchString.toLowerCase()) || data.FeedbackDueDate.toLowerCase().includes(searchString.toLowerCase()) || data.dateReceived.toLowerCase().includes(searchString.toLowerCase()) || data.title.toLowerCase().includes(searchString.toLowerCase()) || searchString === '');
      this.dataSource.data = statusFilterArray.filter(
        data => this.appUtils.getLowerCase(data.LocalId).includes(searchString.toLowerCase()) 
        || this.appUtils.formatDateOnly(data.FeedbackDueDate).includes(searchString.toLowerCase()) 
        || this.appUtils.formatDateOnly(data.CreationDate).includes(searchString.toLowerCase()) 
        || this.appUtils.getLowerCase(data.Title).includes(searchString.toLowerCase()) 
        || this.appUtils.getLowerCase(data.CustomerStatus || data.Status).includes(searchString.toLowerCase())
        || this.appUtils.getLowerCase(data.CustomerName).includes(searchString.toLowerCase())
        || this.appUtils.formatDateOnly(data.DateDelivered).includes(searchString.toLowerCase())
        || this.appUtils.getLowerCase(data.Type).includes(searchString.toLowerCase())
        || searchString === ''
      );
    }
    this.initPageLoad = false;
    this.changeDetect.detectChanges();
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numDisabled = this.dataSource.data.filter(row => row.disabled).length;
    const numRows = this.dataSource.data.length;
    return numSelected + numDisabled === numRows;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => (!row.disabled ? this.selection.select(row) : ''));
  }

  public OpenNotificationDetails(row: any): void {
    const urlparam = `notificationId=${row.NotificationId}&contactnotificationid=${row.ContactNotificationId}&customerId=${row.CustomerId || row.AccountId}`;
    sessionStorage.setItem('urlParameters', urlparam);
    this.router.navigate(['/customer-notification-details']);
  }

  public ngOnDestroy(): void {
    this._accSelectorSubscription?.unsubscribe();
    this._accDataSubscription?.unsubscribe();
    this._searchSubscription?.unsubscribe();
    this._filterSubscription?.unsubscribe();
    this._myNotificationSubscription?.unsubscribe();
  }
}
