<dl-qnsbn-header-logo title="Product & Safety care"></dl-qnsbn-header-logo>
<div class="page-content">
    <div class="contact-container">
        <div class="contact-content">
            <mat-card oneCard class="cd-contact-card-customer">
                <mat-card-content>
                    <form [formGroup]="contactDataForm">
                        <mat-form-field>
                            <mat-label i18n="Location@@notifications.contact.detail.label.location">Location </mat-label>
                            <mat-select oneSelect 
                            placeholder="- Country"
                            formControlName="fcCountry"
                            appearance="fill"
                            size="big"
                            ngDefaultControl
                            >
                                <mat-option value="" i18n="Country@@notifications.contact.detail.option.country">- Country</mat-option>
                                <mat-option *ngFor="let item of countryList" [value]="item">{{ item }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" oneFormField>
                            <mat-label i18n="Email@@notifications.contact.detail.label.email">Email</mat-label>
                            
                            <input matInput formControlName="fcEmail" />
                            
                            <button *ngIf="contactDataForm.controls['fcEmail'].value" mat-icon-button one-icon-button matSuffix (click)="contactDataForm.patchValue({fcEmail: ''});">
                                <mat-icon matSuffix>clear </mat-icon>
                            </button>
                            <mat-error *ngIf="contactDataForm.controls['fcEmail'].invalid" i18n="Error email@@notifications.contact.detail.error.email">Email must be in in proper email format (i.e abc@abc.com)</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" oneFormField>
                            <mat-label i18n="First name@@notifications.contact.detail.label.firstname">First name</mat-label>
                            
                            <input matInput formControlName="fcFirstName" />
                            
                            <button *ngIf="contactDataForm.controls['fcFirstName'].value" mat-icon-button one-icon-button matSuffix (click)="contactDataForm.patchValue({fcFirstName: ''});">
                                <mat-icon matSuffix>clear </mat-icon>
                            </button>
                            <mat-error *ngIf="contactDataForm.controls['fcFirstName'].invalid" i18n="Error First name@@notifications.contact.detail.error.firstname">First name must be at least 2 characters and should not contain any numeric or special character</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" oneFormField>
                            <mat-label i18n="Middle name@@notifications.contact.detail.label.middlename">Middle name (Optional)</mat-label>
                            
                            <input matInput formControlName="fcMiddleName" />
                            
                            <button *ngIf="contactDataForm.controls['fcMiddleName'].value" mat-icon-button one-icon-button matSuffix (click)="contactDataForm.patchValue({fcMiddleName: ''});">
                                <mat-icon matSuffix>clear </mat-icon>
                            </button>
                            <mat-error *ngIf="contactDataForm.controls['fcMiddleName'].invalid" i18n="Error Middle name@@notifications.contact.detail.error.middlename">Middle name should be at least 1 characters and should not contain any numeric or special character</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" oneFormField>
                            <mat-label i18n="Last name@@notifications.contact.detail.label.lastname">Last name</mat-label>
                            
                            <input matInput formControlName="fcLastName" />
                            
                            <button *ngIf="contactDataForm.controls['fcLastName'].value" mat-icon-button one-icon-button matSuffix (click)="contactDataForm.patchValue({fcLastName: ''});">
                                <mat-icon matSuffix>clear </mat-icon>
                            </button>
                            <mat-error *ngIf="contactDataForm.controls['fcLastName'].invalid" i18n="Error Last name@@notifications.contact.detail.error.lastname">Last name must be at least 2 characters and should not contain any numeric or special character</mat-error>
                        </mat-form-field>
                        <div class="cd-contact-list-actionbutton">
                            <button one-secondary-button mat-stroked-button (click)="Oncancel()" class='cnl-btn' i18n="Back@@notifications.contact.detail.button.back">Back</button>
                            <button mat-flat-button color="primary" [disabled]="contactDataForm.invalid" (click)="SubmitFeedback()" i18n="Submit@@notifications.contact.detail.button.submit">Submit</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
