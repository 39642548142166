<mat-form-field
    oneSearchField
    oneSearchFieldBorder="rounded"
    oneSearchFieldAppearance="outline"
    oneSearchFieldSize="slim"
    class="notification-list-search"
  >
    <mat-icon matPrefix>
      search
    </mat-icon>
    <input matInput placeholder="Search" i18n-placeholder="Search@@search.bar.placeholder" [formControl]="notificationSearch" (keyup)="applyFilter($event.target.value)"/>
    <button *ngIf="notificationSearch.value" one-icon-button-flat mat-flat-button matSuffix (click)="notificationSearch.setValue(''); applyFilter('')">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>