<div class="customer-overview-table">
  <one-table>
    <one-table-content>
      <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-tbl">
        <ng-container matColumnDef="Type">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Type@@notifications.table.header.type">Type</mat-header-cell>
          <mat-cell *matCellDef="let element" [title]="element.Type"><div class="truncate-text">{{ element.Type }}</div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="LocalId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let element" [title]="element.LocalId"
            ><div class="truncate-text">{{ element.LocalId }}</div>
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="Title">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Title@@notifications.table.header.title">Title</mat-header-cell>
          <mat-cell *matCellDef="let element" [title]="element.Title"><div class="truncate-text">{{ element.Title }}</div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Customer Name@@notifications.table.header.customerName">Account Name</mat-header-cell>
          <mat-cell *matCellDef="let element" [title]="element.CustomerName || element.AccountName"><div class="truncate-text">{{ element.CustomerName || element.AccountName }}</div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="CreationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Date Received@@notifications.table.header.date-received">Date Received</mat-header-cell>
          <!-- <mat-cell *matCellDef="let element" [title]="element.DateDelivered"><div class="truncate-text">{{ appUtils.formatDateOnly(element.DateDelivered) | date: 'mediumDate' }}</div></mat-cell> -->
          <mat-cell *matCellDef="let element" [title]="element.DateDelivered"><div class="truncate-text">{{ appUtils.formatDateOnly(element.DateDelivered) }}</div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="DeadlineFeedback">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Deadline Feedback@@notifications.table.header.deadline-feedback">Deadline Feedback</mat-header-cell>
          <!-- <mat-cell *matCellDef="let element" [title]="element.FeedbackDueDate"><div class="truncate-text">{{ appUtils.formatDateOnly(element.FeedbackDueDate) | date: 'mediumDate' }}</div></mat-cell> -->
          <mat-cell *matCellDef="let element" [title]="element.FeedbackDueDate"><div class="truncate-text">{{ appUtils.formatDateOnly(element.FeedbackDueDate) }}</div></mat-cell>
        </ng-container>

      <ng-container matColumnDef="CustomerStatus" style="display: flex;">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="Customer Status@@notifications.table.header.customer-status"> Status </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: space-between;" [title]="appUtils.getStatusText(element.CustomerStatus || element.Status)">
          <mat-chip [ngClass]="appUtils.getColor(element.CustomerStatus || element.Status)" class="notification-list-source-chip truncate-text" oneChip oneChipAppearance="outline"> {{ appUtils.getStatusText(element.CustomerStatus || element.Status) }}</mat-chip>
          <mat-chip *ngIf="accSelected === 'My notifications' && element.Submitter !== element.CustomerContactEmail && element.Submitter !== undefined" class="notification-list-source-chip truncate-text" oneChip oneChipAppearance="outline" title="Colleague" i18n="Colleague@@notifications.table.cell.colleague">Colleague</mat-chip>
          <i class="one-icons-legacy">arrow_right</i>
        </mat-cell>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ checked: selection.isSelected(row), disabled: row.disabled }" class="one-element-row" (click)="OpenNotificationDetails(row)"></mat-row>
    </mat-table>
  </one-table-content>
</one-table>
<div class="noDataView" *ngIf="!initPageLoad; else showLoading">
  <ng-container *ngIf="tableData.length < 1; else noFilteredData" i18n="No data available.@@notifications.table.data.empty">
    No data available.
  </ng-container>
  <ng-template #noFilteredData>
    <ng-container *ngIf="dataSource.data.length < 1; else noSearchedData" i18n="No data available with given filter criteria.@@notifications.table.data.search">
      No data available with given filter criteria.
    </ng-container>
    <ng-template #noSearchedData>
      <ng-container *ngIf="dataSource.filteredData.length < 1" i18n="No data available with given search criteria.@@notifications.table.data.filter">
        No data available with given search criteria.
      </ng-container>
    </ng-template>
  </ng-template>
</div>
<ng-template #showLoading>
  <div class="noDataView" *ngIf="dataSource.data.length < 1" i18n="Fetching data...@@notifications.table.data.fetching">
    Fetching data...
  </div>
</ng-template>
</div>