<dl-qnsbn-header-logo title="Product & Safety care"></dl-qnsbn-header-logo>
<div class="page-content">
    <div class="topbar-container" *ngIf="notificationDetailsObj">
        <div>
            <mat-card oneCard class="cd-card-customer">
                <mat-card-title class="cd-mat-card">
                    <div class="cd-feedback-title">{{notificationDetailsObj.Title}}</div>
                    <mat-chip [ngClass]="appUtil.getColor(notificationDetailsObj.CustomerStatus || notificationDetailsObj.Status)" class="notification-list-source-chip truncate-text cd-mat-btn" oneChip oneChipAppearance="outline" >
                        {{ appUtil.getStatusText(notificationDetailsObj.CustomerStatus || notificationDetailsObj.Status) }}
                    </mat-chip>
                    <mat-chip *ngIf="accSelector === 'My notifications' && notificationDetailsObj.Submitter !== ContactNotificationObj.CustomerContactEmail && notificationDetailsObj.Submitter !== undefined" class="notification-list-source-chip truncate-text cd-mat-btn" oneChip oneChipAppearance="outline" title="Colleague"  i18n="Colleague@@notifications.detail.status.colleague">Colleague</mat-chip>
                </mat-card-title>
                <hr class="horizontal-line">
                <div *ngIf="remindersData.length > 0 && remindersData[0].ReminderSendDate" class="cd-feedback-list" >
                    <div class="cd-reminder-title">
                        <one-expandable appearance="squared" [disabled]="isReminderDisabled">
                            <div one-expandable-title i18n="Reminder history@@notifications.detail.label.reminder.history">
                                Reminder history
                                <div class="cd-reminder-list first-child">
                                    <div class="cd-feedback-list-title">{{ remindersData[0].ReminderSubject }} <span style="float:right;" [title]="remindersData[0].ReminderSendDate">{{ appUtil.formatDateOnly(remindersData[0].ReminderSendDate) }}</span></div>
                                    <div class="cd-feedback-list-subject" [innerHTML]="remindersData[0].ReminderMessage"></div>
                                </div>
                            </div>
                            <div one-expandable-content>
                            <div class="cd-reminder-list" *ngFor="let reminder of remindersData; let i = index">
                                <ng-container *ngIf="reminder.ReminderSendDate && i !== 0">
                                    <div class="cd-feedback-list-title">{{ reminder.ReminderSubject }} <span style="float:right;" [title]="reminder.ReminderSendDate">{{ appUtil.formatDateOnly(reminder.ReminderSendDate) }}</span></div>
                                    <div class="cd-feedback-list-subject" [innerHTML]="reminder.ReminderMessage"></div>
                                </ng-container>
                            </div>
                            </div>
                        </one-expandable>
                    </div>
                    <hr class="horizontal-line">
                </div>
                <mat-card-content>
                    <div class="cd-feedback-list" >
                        <div class="cd-feedback-list-title" *ngIf="customerDataObj.MessageTitle && customerDataObj.MessageTitle.length > 0">{{ customerDataObj.MessageTitle }}</div>
                        <div class="cd-feedback-list-subject" *ngIf="customerDataObj.MessageBody">
                            <div [innerHTML]="customerDataObj.MessageBody"></div>
                        </div>
                    </div>
                    <hr class="horizontal-line">
                    <div class="cd-feedback-list" *ngIf="customerDataObj.NotificationAttachments && customerDataObj.NotificationAttachments.length > 0">
                        <div class="cd-feedback-list-title" i18n="Attachments@@notifications.detail.label.attachments"> Attachments </div>
                        <div class="file-upload-multiple">
                        <!--Local Attachment-->
                            <div *ngFor="let attachmentData of customerDataObj.NotificationAttachments">
                                <div class="cd-files-list" *ngIf="customerDataObj.NotificationAttachments && customerDataObj.NotificationAttachments.length > 0">
                                    <div class="single-file">
                                        <div class="info" (click)="downloadAttachment(attachmentData.FileName, customerDataObj.NotificationId)">
                                            <mat-icon class="one-icons iconcls">{{ appUtil.formatFilesIcons(attachmentData.FileName) }}</mat-icon>
                                            <span style="margin-left:10px">
                                            <span class="name">
                                                {{ attachmentData.FileName }}
                                            </span>
                                            <p class="size">{{ formatBytes(attachmentData.FileSize) }}</p></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!--Local Attachment end-->
                        </div>
                        <hr class="horizontal-line" />
                    </div>
                    <div class="cd-feedback-list">
                        <div class="cd-feedback-list-title" *ngIf="customerDataObj.CustomerFeedbackItems && customerDataObj.CustomerFeedbackItems.length > 0" i18n="Feedback@@notifications.detail.label.feedback"> Feedback </div>
                        <!--For FeedBack Submit-->
                        <form [formGroup]="feedbackDataForm">
                            <div *ngIf="accSelector  === 'My notifications'">
                                <div *ngIf="(isFeedBackSubmitted || isCompleted) && (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems); else feedbakNotSubmitted" class="cd-feedback-list" >
                                    <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">   
                                        <div *ngFor="let responseres of (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems)">
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <section class="example-section">
                                                    <p>
                                                        <mat-checkbox type="checkbox" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [(ngModel)]="responseres.FeedbackResponseItemLabel" [disabled]="isFeedBackSubmitted || isCompleted">{{ feedbackdatas.FeedbackItemLabel }}</mat-checkbox>
                                                        <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                    </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <div>
                                                    {{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </div>
                                                <input type="text" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer" i18n-placeholder="Your answer@@feedback.text.placeholder" [attr.disabled]="isFeedBackSubmitted || isCompleted ? true:null" [(ngModel)]="responseres.FeedbackResponseItemLabel"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <div>
                                                    <mat-form-field>
                                                        <mat-label i18n="Select@@feedback.text.Select">Select...</mat-label>
                                                        <mat-select [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted" [(ngModel)]="responseres.FeedbackResponseItemLabel">
                                                            <mat-option [value]="opts" *ngFor="let opts of feedbackdatas.FeedbackItemDropdown">
                                                                {{opts}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <mat-radio-group  
                                                        class="radio-group" oneCustomKeyboardHandler aria-labelledby="radio-group-label"
                                                        [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" 
                                                        [disabled]="isFeedBackSubmitted || isCompleted" 
                                                        [(ngModel)]="responseres.FeedbackResponseItemLabel"
                                                    >
                                                    <mat-radio-button  class="radio-button" [value]="optsRadio" *ngFor="let optsRadio of feedbackdatas.FeedbackItemRadio">
                                                        {{ optsRadio }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div> 
                                </div>

                                <ng-template #feedbakNotSubmitted>
                                    <div class="cd-feedback-list">
                                        <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">
                                            <div class="cd-feedback-list-checkbox" *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox'">
                                                <section class="example-section">
                                                <p><mat-checkbox [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted">{{ feedbackdatas.FeedbackItemLabel }}</mat-checkbox>
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question'">
                                                <div>{{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </div>
                                                <input [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer" i18n-placeholder="Your answer@@feedback.text.placeholder" [attr.disabled]="isFeedBackSubmitted || isCompleted ? true:null"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown'">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <div>
                                                    <mat-form-field>
                                                        <mat-label i18n="Select@@feedback.text.Select">Select...</mat-label>
                                                        <mat-select [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted">
                                                            <mat-option [value]="opts" *ngFor="let opts of feedbackdatas.FeedbackItemDropdown">
                                                                {{opts}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio'">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <mat-radio-group
                                                        class="radio-group" oneCustomKeyboardHandler aria-labelledby="radio-group-label"
                                                        [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" 
                                                        [disabled]="isFeedBackSubmitted || isCompleted" 
                                                    >
                                                    <mat-radio-button  class="radio-button" [value]="optsRadio" *ngFor="let optsRadio of feedbackdatas.FeedbackItemRadio">
                                                        {{ optsRadio }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                            <div *ngIf="accSelector  !== 'My notifications'">
                                <div *ngIf="(isFeedBackSubmitted || isCompleted) 
                                    && (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems); else feedbakNotSubmitted" class="cd-feedback-list" >
                                    <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">   
                                        <div *ngFor="let responseres of (detailsAPIRes.ContactNotification[0].CustomerFeedbackResponseItems || detailsAPIRes.AccountNotification[0].FeedbackResponseItems)">
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <section class="example-section">
                                                    <p>
                                                        <mat-checkbox type="checkbox" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [(ngModel)]="responseres.FeedbackResponseItemLabel" [disabled]="isFeedBackSubmitted || isCompleted">{{ feedbackdatas.FeedbackItemLabel }}</mat-checkbox>
                                                        <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                    </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <div>
                                                    {{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </div>
                                                <input type="text" [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer" i18n-placeholder="Your answer@@feedback.text.placeholder" [attr.disabled]="isFeedBackSubmitted || isCompleted ? true:null" [(ngModel)]="responseres.FeedbackResponseItemLabel"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <div>
                                                    <mat-form-field>
                                                        <mat-label i18n="Select@@feedback.text.Select">Select...</mat-label>
                                                        <mat-select [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted" [(ngModel)]="responseres.FeedbackResponseItemLabel">
                                                            <mat-option [value]="opts" *ngFor="let opts of feedbackdatas.FeedbackItemDropdown">
                                                                {{opts}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio' && feedbackdatas.FeebackItemId == responseres.FeedbackResponseItemId">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <mat-radio-group  
                                                        class="radio-group" oneCustomKeyboardHandler aria-labelledby="radio-group-label"
                                                        [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" 
                                                        [disabled]="isFeedBackSubmitted || isCompleted" 
                                                        [(ngModel)]="responseres.FeedbackResponseItemLabel"
                                                    >
                                                    <mat-radio-button  class="radio-button" [value]="optsRadio" *ngFor="let optsRadio of feedbackdatas.FeedbackItemRadio">
                                                        {{ optsRadio }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div> 
                                </div>

                                <ng-template #feedbakNotSubmitted>
                                    <div class="cd-feedback-list">
                                        <div *ngFor="let feedbackdatas of customerDataObj.CustomerFeedbackItems">
                                            <div class="cd-feedback-list-checkbox" *ngIf="feedbackdatas.FeedbackItemType == 'Checkbox'">
                                                <section class="example-section">
                                                <p><mat-checkbox [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted">{{ feedbackdatas.FeedbackItemLabel }}</mat-checkbox>
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                </section>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Question'">
                                                <div>{{ feedbackdatas.FeedbackItemLabel }}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </div>
                                                <input [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" class="cd-feedback-list-answer" placeholder="Your answer" i18n-placeholder="Your answer@@feedback.text.placeholder" [attr.disabled]="isFeedBackSubmitted || isCompleted ? true:null"/>
                                            </div>
                                            <div *ngIf="feedbackdatas.FeedbackItemType == 'Dropdown'">
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <div>
                                                    <mat-form-field>
                                                        <mat-label i18n="Select@@feedback.text.Select">Select...</mat-label>
                                                        <mat-select [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" [disabled]="isFeedBackSubmitted || isCompleted">
                                                            <mat-option [value]="opts" *ngFor="let opts of feedbackdatas.FeedbackItemDropdown">
                                                                {{opts}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <!-- Radio Start -->
                                            <div  *ngIf="feedbackdatas.FeedbackItemType == 'Radio'">
                                                <!-- Header -->
                                                <p>
                                                    {{ feedbackdatas.FeedbackItemLabel}}
                                                    <span  *ngIf="feedbackdatas.FeedbackItemMandatory == 'Optional'" style="margin-left: 6px">(<span class="chipMandatory" [ngClass]="{ chipOptional: feedbackdatas.FeedbackItemMandatory == 'Optional'}" i18n="Optional@@feedback.text.Optional">{{feedbackdatas.FeedbackItemMandatory}}</span>)</span>
                                                </p>
                                                <!-- Options -->
                                                <div>
                                                    <mat-radio-group
                                                        class="radio-group" oneCustomKeyboardHandler aria-labelledby="radio-group-label"
                                                        [formControlName]="getFormControl(feedbackdatas.FeebackItemId)" 
                                                        [disabled]="isFeedBackSubmitted || isCompleted" 
                                                    >
                                                    <mat-radio-button  class="radio-button" [value]="optsRadio" *ngFor="let optsRadio of feedbackdatas.FeedbackItemRadio">
                                                        {{ optsRadio }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                </div>
                                            </div>
                                            <!-- Radio END -->
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <!--FeedBack Form end-->
                            <div class="cd-feedback-list-actionbutton">
                                <button one-secondary-button mat-stroked-button *ngIf="!isFeedBackSubmitted && !isCompleted" (click)="Oncancel()" class='cnl-btn' i18n="Cancel@@notifications.detail.button.cancel">Cancel</button>
                                <button mat-flat-button color="primary" *ngIf="!isFeedBackSubmitted && !isCompleted && !isNonDialogSBNType" [disabled]="feedbackDataForm.invalid" (click)="SubmitFeedback()" i18n="Submit@@notifications.detail.button.submit">Submit</button>
                                <button mat-flat-button color="primary" *ngIf="!isFeedBackSubmitted && !isCompleted && isNonDialogSBNType" [disabled]="feedbackDataForm.invalid" (click)="nonDialogConfirmFeedback()" i18n="Confirm@@notifications.detail.button.confirm">Confirm</button>
                                <button mat-flat-button color="primary" *ngIf="isFeedBackSubmitted || isCompleted" (click)="OnBack()" i18n="Back@@notifications.detail.button.back">Back</button>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="mat-error data-error" *ngIf="isApIFailed">
        {{ msgAPIFailed }}
    </div>
</div>