import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';

export const environment: EnvironmentInterface = {
  production: true,
  appUrlId: 'dialog',
  featureFlags: { groups: false, favorites: false, getHelp: true, welcomePopup: false, apiCallSSO: true },
  apiUrl: 'https://qn-sbn-sandbox.roche.com/sandbox/api/v1',
  dialogUrl: 'rexis--apollo.sandbox.my.site.com',
  appUrl: 'https://qn-sbn-sandbox.roche.com',
  hubUrl: 'https://esrv-hub-dev.roche.com',
  allOtherCountriesUrl: 'https://pim-eservices.roche.com/eLD/web/pi/en/home',
  orgId: '00D1w0000000WQD',
  recordTypeId: '012b0000000M2qA',
  groupsPageLimit: 20,
  cognito: {
    domain: 'auth.qn-sbn-sandbox.roche.com',
    clientId: 'hhu4778699gq48ib4fff3j5ti',
    redirectSignInUrl: 'https://qn-sbn-sandbox.roche.com/login',
    redirectSignOutUrl: 'https://qn-sbn-sandbox.roche.com/logout',
    userPoolId: 'eu-central-1_cp0n3xkkS',
    samlIdP: 'Dialog'
},
  contactIdPlaceholder: 'PATH_CONTACT_ID',
  providers: [],
  availableLocales: {
    au: {
      en_au: true,
      en_us: true
    },
    ar: {
      es_ar: true
    },
    ae: {
      en_us: true
    },
    bg: {
      bg: true
    },
    ca: {
      en_ca: true,
      fr_ca: true
    },
    cl: {
      es: true,
      es_cl: true
    },
    co: {
      es: true,
      es_co: true
    },

    be: {
      en_us: true,
      fr_be: true,
      nl_be: true
    },
    br: {
      pt_br: true,
      en_us: true,
      es: true
    },
    dk: {
      da: true
    },
    cz: {
      cs: true
    },
    de: {
      de: true,
      en_us: true
    },
    at: {
      de_at: true,
      en_us: true
    },
    ch: {
      de_ch: true,
      fr_ch: true,
      it_ch: true,
      en_us: true
    },
    us: {
      en_us: true
    },
    gb: {
      en_gb: true
    },
    gt: {
      es_gt: true
    },
    za: {
      en_za: true
    },
    ee: {
      et: true,
      en_us: true
    },
    eg: {
      en_us: true
    },
    es: {
      es: true
    },
    fi: {
      fi: true,
      en_us: true
    },
    fr: {
      fr: true,
      en_us: true
    },
    hr: {
      hr: true,
      en_us: true
    },
    hu: {
      hu: true,
      en_us: true
    },
    id: {
      en_us: true
    },
    it: {
      it: true,
      en_us: true
    },
    is: {
      en_us: true
    },
    in: {
      en_gb: true
    },
    il:{
      en_gb: true
    },
    jp: {
      ja: true,
      en_us: true
    },
    kr: {
      ko: true,
      en_us: true
    },
    lt: {
      lt: true,
      en_us: true
    },
    lv: {
      lv: true,
      en_us: true
    },
    lu: {
      en_us: true,
      fr: true,
      de: true
    },
    mt: {
      en_us: true,
      en_gb: true
    },
    my: {
      en_us: true
    },
    mm: {
      en_us: true
    },
    mx: {
      es_mx: true
    },
    nz: {
      en_us: true
    },
    nl: {
      nl_nl: true,
      en_us: true
    },
    no: {
      no: true,
      en_us: true
    },
    pk: {
      en_us: true
    },
    pl: {
      pl: true
    },
    pa: {
      es_pa: true,
      en_us: true
    },
    pe: {
      es_pe: true
    },
    ph: {
      en_us: true
    },
    pi: {
      en_us: true
    },
    pt: {
      pt_pt: true
    },
    ro: {
      ro: true,
      en_us: true
    },
    ru: {
      ru: true,
      en_gb: true
    },
    sa: {
      en_us: true
    },
    sg: {
      en_us: true
    },
    sk: {
      sk: true,
      en_us: true
    },
    sl: {
      sl: true
    },
    se: {
      sv: true,
      en_us: true
    },
    si: {
      sl: true,
      en_gb: true
    },
    th: {
      th: true,
      en_us: true
    },
    tr: {
      tr: true,
      en_us: true,
      ru: true
    },
    tw: {
      zh_tw: true,
      en_us: true
    },
    ua: {
      en_us: true
    },
    uy: {
      es_uy: true
    },
    vn: {
      vi: true,
      en_us: true
    },
    ve: {
      es: true
    },
    gr: {
      el: true,
      en_us: true
    },
    cn: {
      en_us: true,
      zh_cn: true,
      zh_tw: true
    },
    hk: {
      en_us: true
    },
    ec: {
      es_ec: true
    },
    cy: {
      el: true
    },
    xo: {
      en_us: true
    }
  },
  redirectAffiliate: {
    al: 'fr',
    bf: 'fr',
    yt: 'fr',
    bj: 'fr',
    cm: 'fr',
    cv: 'fr',
    cf: 'fr',
    td: 'fr',
    cd: 'fr',
    cg: 'fr',
    gf: 'fr',
    ci: 'fr',
    dj: 'fr',
    eh: 'fr',
    gq: 'fr',
    ga: 'fr',
    gm: 'fr',
    gn: 'fr',
    gw: 'fr',
    gp: 'fr',
    lr: 'fr',
    lu: 'fr',
    ml: 'fr',
    mq: 'fr',
    mr: 'fr',
    mc: 'fr',
    mg: 'fr',
    ma: 'fr',
    ne: 'fr',
    re: 'fr',
    st: 'fr',
    sn: 'fr',
    sl: 'fr',
    tg: 'fr',
    tn: 'fr',
    ie: 'gb',
    ad: 'es',
    az: 'tr',
    ge: 'tr',
    kz: 'tr',
    kg: 'tr',
    tj: 'tr',
    tm: 'tr',
    uz: 'tr',
    bh: 'ae',
    ir: 'ae',
    iq: 'ae',
    jo: 'ae',
    kw: 'ae',
    lb: 'ae',
    ly: 'ae',
    mv: 'ae',
    om: 'ae',
    ps: 'ae',
    qa: 'ae',
    sy: 'ae',
    ye: 'ae',
    ba: 'hr',
    ag: 'pa',
    aw: 'pa',
    bs: 'pa',
    bb: 'pa',
    bz: 'pa',
    bm: 'pa',
    bq: 'pa',
    gd: 'pa',
    ht: 'pa',
    sr: 'pa',
    vg: 'pa',
    ky: 'pa',
    cr: 'pa',
    cu: 'pa',
    cw: 'pa',
    dm: 'pa',
    do: 'pa',
    sv: 'pa',
    gy: 'pa',
    hn: 'pa',
    jm: 'pa',
    ni: 'pa',
    tt: 'pa',
    tc: 'pa',
    kn: 'pa',
    vc: 'pa',
    bo: 'uy',
    py: 'uy',
    bw: 'za',
    bi: 'za',
    er: 'fr',
    et: 'za',
    gh: 'za',
    ke: 'za',
    ls: 'za',
    mw: 'za',
    mu: 'za',
    mz: 'za',
    na: 'za',
    ng: 'za',
    rw: 'za',
    so: 'za',
    sd: 'za',
    sz: 'za',
    ug: 'za',
    zm: 'za',
    zw: 'za',
    tz: 'za',
    li: 'ch',
    kh: 'th'
  },
  apiProcessSuffix: '/process',
  apiUserSysSuffix: '/users-sys',
  oneTrustDomainId: '',
  scripts: []
};
