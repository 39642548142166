<dl-qnsbn-header-logo title="Product & Safety care"></dl-qnsbn-header-logo>
<div class="page-content">
  <div *ngIf="userType === 'isNonDialogUser' || userType === 'isNotLoginDialogUser'; else custNotificationFlow">
    <!-- <div>Non Dialog User details should be display here.</div>
    <dl-customer-notification-details></dl-customer-notification-details> -->
  </div>
  <ng-template  #custNotificationFlow>
    <div class="cn-notification-container">
      <mat-card class="notification-list" style="box-shadow: none;">
        <div>
          <div class="notification-list-title" i18n="Filter By|Notification type filter@@notifications.overview.title">
            Notifications overview
          </div>
          <div class="notification-search-rows">
            <dl-notification-search></dl-notification-search>
          </div>
        </div>
        <div class="notification-filter-rows">
          <dl-notification-filter></dl-notification-filter>
        </div>
      </mat-card>
      <dl-notification-table></dl-notification-table>
    </div>
  </ng-template>
</div>