import { ChangeDetectionStrategy, Inject, Component, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { ENVIRONMENT } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { OpenLinkWithReferrerService } from '@dialog-eservices-enablement/angular-components';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';

@Component({
  selector: 'dl-nondialog-customer-welcome',
  templateUrl: './nondialog-customer-welcome.component.html',
  styleUrls: ['./nondialog-customer-welcome.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NondialogCustomerWelcomeComponent implements OnInit, AfterViewInit {
  public notificationData;
  public constructor(private openLinkWithReferrerService: OpenLinkWithReferrerService, @Inject(ENVIRONMENT) private environment: EnvironmentInterface, public customerservice: CustomerNotificationServiceService) {
    this.notificationData = history.state.notificationData;
  }

  public ngOnInit(): void {}
  
  public ngAfterViewInit(): void {
    const localId = this.notificationData ? this.notificationData.LocalId : '';
    this.customerservice.setLocalId(localId);
  }

  public openRegistration(): void {
    const rememberedLocation = JSON.parse(localStorage.getItem('rememberMyLocation'));
    const url = `https://${this.environment.dialogUrl}/${rememberedLocation.countryCode}/${rememberedLocation.languageCode}/registration`;
    this.openLinkWithReferrerService.openLink(url, false);
  }
}
