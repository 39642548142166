<dl-qnsbn-header-logo title="Product & Safety care"></dl-qnsbn-header-logo>
<div class="welcome-container">
    <!-- <mat-toolbar oneTopbar class="welcome-main-topbar">
        <mat-toolbar-row>
            <span one-topbar-title i18n="Welcome to DiaLog@@notifications.welcome.title.welcome">Welcome to DiaLog!</span>
        </mat-toolbar-row>
    </mat-toolbar> -->

    <div class="welcome-content">
        <div class="background-shape"></div>
        <div class="background-thread"></div>
        <div class="background-kite"></div>
        <div class="welcome-text-block">
            <h3 i18n="Sent it@@notifications.welcome.title.sent">Sent it!</h3>
            <p i18n="Welcome body@@notifications.welcome.body.text">Your Navify account helps you save time by automatically filling in your contact details</p>
            <!-- <p><a href="javascript:void(0)" (click)="openRegistration()" i18n="Register now link | Link that redirects user to a register page@@encourage-login-modal.encourage-login-modal.register-button" data-translation-id="encourage-login-modal.encourage-login-modal.register-button">Create an account now</a></p> -->
        </div>
    </div>
</div>