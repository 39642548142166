import { ChangeDetectionStrategy, Inject, Component, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy, Renderer2 } from '@angular/core';
import { OneBreadcrumb } from '@one/angular-kit/navigation';
import { MatChipsModule } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';
import { AppUtils } from 'projects/dialog/src/app/shared/utilities/app-utils';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dl-customer-notification-details',
  templateUrl: './customer-notification-details.component.html',
  styleUrls: ['./customer-notification-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerNotificationDetailsComponent implements OnInit, OnDestroy {
  public breadcrumbs: OneBreadcrumb[];
  public isDisabled = true;
  public notificationDataObj: any;
  public feedBackDetails: any;
  public notificationDetailsObj: any;
  public customerDataObj: any;
  public feedBackData: any;
  public customerFeedbackItems: any;
  public feedbackobj: any;
  public feedbackDataForm: FormGroup;
  public isFeedBackSubmitted = false;
  public isCompleted = false;
  public userFileSize = { size: 0, type: '' };
  public urlQueryParameter = sessionStorage.getItem('urlParameters');
  public userType = localStorage.getItem('userType');
  public isNonDialogUser = this.userType === 'isNonDialogUser' ? true : false;
  public notificationType = '';
  public isNonDialogSBNType = false;
  public isApIFailed = false;
  public msgAPIFailed = '';
  public remindersData = [];
  public isReminderDisabled = false;
  public ContactNotificationObj: any;
  private _accSelectorSubscription: Subscription | undefined;
  public accSelector:string = '';
  public detailsAPIRes:any;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    public _changeDetect: ChangeDetectorRef,
    public renderer: Renderer2,
    public fb: FormBuilder,
    public appUtil: AppUtils,
    public router: Router,
    public customerservice: CustomerNotificationServiceService,
    public activateroute: ActivatedRoute,
    public readonly oneSnackBar?: OneSnackBarService
  ){
    this.renderer.addClass(document.body, 'local-notification-config');
  }

  public ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'local-notification-config');
    if (!this.isNonDialogSBNType) {
      sessionStorage.removeItem('urlParameters');
    }
    this._accSelectorSubscription?.unsubscribe();
  }

  public ngAfterContentInit(): void {
    
  }

  public ngOnInit(): void {

    this.breadcrumbs = [
      {
        label: 'Customer-Notifications',
        url: '/customer-notification'
      }
    ];
    
    this._accSelectorSubscription = this.customerservice.accountSelector$.subscribe(
      res => {
        this.accSelector = res
      }
    );

    // if (this.urlQueryParameter && (this.userType === 'isLoginDialogUser' || this.userType === 'isNonDialogUser')) {
    if (this.userType === 'isLoginDialogUser' || this.userType === 'isNonDialogUser') {
      this.customerservice.getNotificationDetailsDirectLink(this.urlQueryParameter).subscribe(
        (data: any) => {
          if (Object.keys(data).length > 0) {
            this.detailsAPIRes = data;
            this.notificationDetailsInit(data, this.accSelector);
          } else {
            // data with error message 401
            // const errResponse = JSON.parse(data);
            const errorMessage = this.appUtil.getTranslatedMessage('203');
            this.customerservice.httpErrorHandler({ status: 203 }, errorMessage);
            this.isApIFailed = true;
            this.msgAPIFailed = errorMessage;
            this._changeDetect.detectChanges();
          }
        },
        (error: HttpErrorResponse) => {
          // console.log('error:', error);
          this.isApIFailed = true;
          if (error.status === 403) {
            // this.oneSnackBar.open(error.error.Message, OneSnackBarType.ERROR, '', { duration: 5000 });
            this.msgAPIFailed = this.appUtil.getTranslatedMessage('403'); // 'Invalid link. User is not authorized to access this information. Please contact your local Roche Product and Safety Care contact.'; // error.error.Message;
          } else if (error.status === 404) {
            this.msgAPIFailed = this.appUtil.getTranslatedMessage('404'); // 'Could not find notification. Please contact your local Roche Product and Safety Care contact. You will be redirect to overview page.';
            setTimeout(() => {
              this.checkNonDialogUser();
              this.router.navigateByUrl('/customer-notification');
            }, 10000);
          } else {
            const errMessage = this.appUtil.getTranslatedMessage('detailFailed');
            this.customerservice.httpErrorHandler(error, errMessage);
            sessionStorage.removeItem('urlParameters');
            this.router.navigateByUrl('/customer-notification');
          }
          this._changeDetect.detectChanges();
        }
      );
    } else {
      const errorsMessage = this.appUtil.getTranslatedMessage('404');
      this.oneSnackBar.open(errorsMessage, OneSnackBarType.ERROR, '', { duration: 5000 });
      sessionStorage.removeItem('urlParameters');
      this.router.navigateByUrl('/customer-notification');
    }

    this.customerservice.isDDVisible.next(false);
    
  }
  
  public notificationDetailsInit(data: any, accSelector): void {

    if(accSelector === 'My notifications' && accSelector !== undefined){
      this.notificationDetailsObj = Array.isArray(data.ContactNotification) ? data.ContactNotification[0] : data.ContactNotification;
    }else{
      this.notificationDetailsObj = Array.isArray(data.AccountNotification) ? data.AccountNotification[0] : data.AccountNotification;
    }

    this.customerDataObj = Array.isArray(data.Notification) ? data.Notification[0] : data.Notification;
    this.ContactNotificationObj = Array.isArray(data.ContactNotification) ? data.ContactNotification[0] : data.ContactNotification;
    this.notificationType = this.customerDataObj.Type;
    this.isNonDialogSBNType = this.isNonDialogUser && this.notificationType === 'SBN' ? true : false;
    const localId = this.notificationDetailsObj ? this.notificationDetailsObj.LocalId : '';
    this.customerservice.setLocalId(localId);

    if (this.notificationDetailsObj.CustomerReminderDetails) {
      const remindersDataFiltered = this.notificationDetailsObj.CustomerReminderDetails.map((item, i) => {
        const reminderItem = this.customerDataObj.NotificationReminders.filter(reItem => reItem.ReminderId === item.ReminderId);
        return Object.assign({}, reminderItem[0], item);
      });
      this.remindersData = remindersDataFiltered.filter(reItem => reItem.ReminderSendStatus === "Delivered").reverse();
    }

    this.isReminderDisabled = this.remindersData.length === 1;
    this.feedbackDataForm = this.fb.group({});

    if (this.customerDataObj && this.customerDataObj.CustomerFeedbackItems) {
      this.feedbackobj = this.customerDataObj.CustomerFeedbackItems;
      this.feedbackobj.forEach(formItem => {
       if(formItem.FeedbackItemMandatory && formItem.FeedbackItemMandatory === 'Optional') {
          this.feedbackDataForm.addControl(this.getFormControl(formItem.FeebackItemId), this.fb.control(''));
        } else {
          if (formItem.FeedbackItemType === 'Checkbox') {
            this.feedbackDataForm.addControl(this.getFormControl(formItem.FeebackItemId), this.fb.control('', [Validators.requiredTrue]));
          } else {
            this.feedbackDataForm.addControl(this.getFormControl(formItem.FeebackItemId), this.fb.control('', [Validators.required]));
          }
          // check here
          // if (formItem.FeedbackItemType === 'Dropdown') {
          //   this.feedbackDataForm.addControl(this.getFormControl(formItem.FeebackItemId), this.fb.control('', [Validators.requiredTrue]));
          // } else {
          //   this.feedbackDataForm.addControl(this.getFormControl(formItem.FeebackItemId), this.fb.control('', [Validators.required]));
          // }
        }
      });
    }

    if (this.notificationDetailsObj && (this.notificationDetailsObj.CustomerStatus || this.notificationDetailsObj.Status) && this.customerDataObj.Status) {
      
      this.isFeedBackSubmitted = this.notificationDetailsObj.CustomerStatus === 'Feedback Submitted' 
      || this.notificationDetailsObj.CustomerStatus === 'Feedback Received'
      || this.notificationDetailsObj.Status === 'Feedback Submitted'
      || this.notificationDetailsObj.Status === 'Feedback Received';

      this.isCompleted = this.customerDataObj.Status === 'Completed';
      
    }

    this._changeDetect.detectChanges();
  }

  public enablesubmit(): void {
    this.isDisabled = false;
  }

  public getSbtBtn(btnStatus: string): string {
    switch (btnStatus) {
      case 'New':
        return 'sbt-btn';
      case 'Read':
        return 'sbt-btn';
      case 'Reminder':
        return 'sbt-btn';
      case 'Feedback Overdue':
        return 'sbt-btn';
      case 'Feedback Submitted':
        return 'bck-btn';
      case 'Feedback Received':
        return 'bck-btn';
      case 'Completed':
        return 'bck-btn';
    }
  }

  public getSubmitFormData(): void {
    const feedbackVar: any = [];
    const feedbackresArr = this.feedbackDataForm.value;
    const feedbackresponseVal = Object.values(feedbackresArr);

    this.feedbackobj.forEach(formItem => {
      feedbackVar.push({
        FeedbackResponseItemId: formItem.FeebackItemId,
        FeedbackResponseItemLabel: feedbackresponseVal[formItem.FeebackItemId]
      });
    });

    this.feedBackData = {
      NotificationId: this.notificationDetailsObj.NotificationId,
      NotificationType: this.notificationDetailsObj.Type,
      ReadAndUnderstood: true, // this.readconfirm.value,
      WillTakeAction: true, // this.actionconfirm.value,
      CustomerFeedbackResponseItems: feedbackVar,
      CustomerId: this.ContactNotificationObj.CustomerId || '',
      ContactNotificationId: this.ContactNotificationObj.ContactNotificationId || '',
      CustomerContactEmail: this.ContactNotificationObj.CustomerContactEmail || ''
    };

    if (this.isNonDialogUser) {
      this.feedBackData.PublicKeyToken = this.customerservice.getUrlVar(sessionStorage.getItem('urlParameters'), 'publicKeyToken');
    }
  }

  public SubmitFeedback(): void {
    this.getSubmitFormData();
    this.customerservice.putService(this.feedBackData).subscribe(
      (response: HttpResponse<any>) => {
        this.customerservice.storeNotificationTableData = null;
        const feedbackSubmitted = this.appUtil.getTranslatedMessage('FeedbackSubmitted');
        this.oneSnackBar.open(feedbackSubmitted, OneSnackBarType.SUCCESS, '', { duration: 3000 });
        const _self = this;
        setTimeout(function(){
          if (_self.isNonDialogUser) {
            _self.router.navigateByUrl('/welcome');
          } else {
            _self.router.navigateByUrl('/customer-notification');
          }
        }, 5000);
      },
      (error: HttpErrorResponse) => {
        const feedbackFailed = this.appUtil.getTranslatedMessage('FeedbackFailed');
        this.oneSnackBar.open(feedbackFailed, OneSnackBarType.ERROR, '', { duration: 3000 });
      }
    );
  }

  public nonDialogConfirmFeedback(): void {
    this.getSubmitFormData();
    this.router.navigateByUrl('/contact-details', { state: { feedbackData: this.feedBackData, notificationData: this.notificationDetailsObj } });
  }

  public checkNonDialogUser(): void {
    const urlQueryString = sessionStorage.getItem('urlParameters');
    const publicKeyTokenvalue = this.customerservice.getUrlVar(urlQueryString, 'publicKeyToken');
    if (publicKeyTokenvalue && publicKeyTokenvalue !== 'undefined') {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
      this.renderer.addClass(document.body, 'login-modal-view');
      this.authService.openLoginDialog();
    }
    sessionStorage.removeItem('urlParameters');
  }

  public Oncancel(): void {
    this.checkNonDialogUser();
    this.router.navigateByUrl('/customer-notification');
  }

  public OnBack(): void {
    this.checkNonDialogUser();
    this.router.navigateByUrl('/customer-notification');
  }

  public downloadDocument(PIMID: string, fileExtension: string): void {
    this.customerservice.downloadPiosDocumentApi(PIMID, fileExtension);
  }

  public downloadAttachment(fileName: string, notificationId: string): void {
    const urlQueryString = sessionStorage.getItem('urlParameters');
    const contactNotificationId = this.customerservice.getUrlVar(urlQueryString, 'contactNotificationId') || '';
    const publicKey = this.customerservice.getUrlVar(urlQueryString, 'publicKeyToken') || '';
    this.customerservice.downloadLocalAttachmentApi(fileName, notificationId, contactNotificationId, publicKey);
  }

  public getFormControl(ID: any): string {
    return 'formContolLabel' + ID;
  }

  public bytesCalculation(bytes: number, decimals: number = 2): any {
    if (bytes === 0) {
      this.userFileSize.size = 0;
      this.userFileSize.type = '';
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    this.userFileSize.size = 0;
    this.userFileSize.type = '';

    this.userFileSize.size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    this.userFileSize.type = sizes[i];
    return this.userFileSize;
  }

  public formatBytes(bytes: number, decimals: number = 2): string {
    this.bytesCalculation(bytes, decimals);
    return this.userFileSize.size + ' ' + this.userFileSize.type;
  }
}
