import { ChangeDetectionStrategy, Renderer2, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomerNotificationServiceService } from '../services/customer-notification-service.service';

@Component({
  selector: 'dl-customer-notification',
  templateUrl: './customer-notification.component.html',
  styleUrls: ['./customer-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerNotificationComponent implements OnInit {
  public urlQueryParameter = sessionStorage.getItem('urlParameters');
  public userType = localStorage.getItem('userType');
  public constructor(public renderer: Renderer2, public customerservice: CustomerNotificationServiceService) {
  }

  public ngOnInit(): void {
    // console.log('load customer notification component');
  }
}
