import { Inject, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRouterModule } from './app-router.module';
import { AppComponent } from './app.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';

import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { OneIconModule } from '@one/angular-kit/icon';
import { OneCookieAcceptanceModule } from '@one/angular-kit/pattern';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { MatListModule } from '@angular/material/list';
import { OneListModule } from '@one/angular-kit/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
// import { CustomerNotificationComponent } from './features/customer-notification/customer-notification.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CustomerNotificationModule } from './features/customer-notification/customer-notification.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AppRouterModule, MatIconModule, MatSelectModule, HammerModule, MatCardModule, MatDividerModule, OneSnackBarModule, MatListModule, OneListModule, MatButtonModule, CommonModule, RouterModule, MatSidenavModule, HttpClientModule, HttpClientJsonpModule, OneTopbarModule, OneIconModule, OneCookieAcceptanceModule, MatTooltipModule, CustomerNotificationModule, CoreModule.forRoot(), ReactiveFormsModule],
  providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }, ...environment.providers],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(@Inject(DOCUMENT) private document: Document) {
    // console.log(this.document.location.href);
    const isUserSignedIn = localStorage.getItem('amplify-redirected-from-hosted-ui');
    // If isUserSignedIN == null it means user is not signedin through SSO
    /** * Case 1:
     * If user hitting Apllication URL along with query parameter directly
     * it will verify publicTokenKey and save the query parameter from url in localstorage
     * and user will redirect to specific page based on saved parameter
     * URL: https://qn-sbn-sandbox1.roche.com?notificationId=9e25dcd1-9e56-4960-a447-a44039e9ad65&contactNotificationId=fc9dde01-57dd-4caf-bbda-da7e69284962&publicKeyToken=3021e833-1f28-40f6-b1a1-294a04ed8cc0&country=GB
     */
    /** * Case2:
     * If user is LoggedIn and hitting Apllication URL along with query parameter directly
     * it will save the query parameter from url in localstorage and redirect to specific page
     */
    const urlQueryString = this.document.location.search;
    const parameters = new URLSearchParams(urlQueryString);
    const storeUrlData = sessionStorage.getItem('urlParameters');
    const publicKeyTokenvalue = parameters.get('publicKeyToken') || this.getUrlVar(storeUrlData, 'publicKeyToken');
    localStorage.removeItem('userType');
    if (parameters.get('publicKeyToken') || parameters.get('notificationId')) {
      sessionStorage.setItem('urlParameters', parameters.toString());
    }
    if (publicKeyTokenvalue && publicKeyTokenvalue !== 'undefined') {
      localStorage.setItem('userType', 'isNonDialogUser');
      localStorage.removeItem('rememberMyLocation');
    } else {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
    }
  }
  public getUrlVar(urlString: any, key: any): any {
    const result = new RegExp(key + '=([^&]*)', 'i').exec(urlString);
    return (result && unescape(result[1])) || '';
  }
}
