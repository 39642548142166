import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Renderer2, ChangeDetectorRef } from '@angular/core';
import { CustomerNotificationServiceService } from '../../services/customer-notification-service.service';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dl-qnsbn-header-logo',
  templateUrl: './qnsbn-header-logo.component.html',
  styleUrls: ['./qnsbn-header-logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QnsbnHeaderLogoComponent implements OnInit {
  public localId = '';
  constructor(public renderer: Renderer2, private authService: AuthService, public customerservice: CustomerNotificationServiceService, public router: Router, public changeDetect: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.customerservice.localId$.subscribe(localid => {
      this.localId = localid;
      this.changeDetect.detectChanges();
    });
  }
  public redirectToHome():void {
    sessionStorage.removeItem('urlParameters');
    this.router.navigateByUrl('/customer-notification');
    if (localStorage.getItem('userType') === 'isNonDialogUser') {
      localStorage.setItem('userType', 'isNotLoginDialogUser');
      this.renderer.addClass(document.body, 'login-modal-view');
      this.authService.openLoginDialog();
    }
  }
}
