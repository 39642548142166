<div class="notification-list-flt-content">

    <span class="notification-list-filter-txt" i18n="Filter By|Notification type filter@@notifications.list.label-filter.text">
      Filter By: 
    </span>

    <one-filtering-chip
      *ngIf="this.accSelected === 'My notifications'"
      [list]="typeList"
      [placeholder]="typePlaceholder"
      [formControl]="typeFormControl"
      [width]="width">
    </one-filtering-chip>

    <one-filtering-chip
      *ngIf="this.accSelected !== 'My notifications'"
      [list]="accTypeList"
      [placeholder]="typePlaceholder"
      [formControl]="typeFormControl"
      [width]="width">
    </one-filtering-chip>

    <one-filtering-chip
      *ngIf="this.accSelected === 'My notifications'"
      [list]="statusList"
      [placeholder]="statusPlaceholder"
      [formControl]="statusFormControl"
      [width]="widthStatus">
    </one-filtering-chip>

    <one-filtering-chip
      *ngIf="this.accSelected !== 'My notifications'"
      [list]="accStatusList"
      [placeholder]="statusPlaceholder"
      [formControl]="statusFormControl"
      [width]="widthStatus">
    </one-filtering-chip>

    <button *ngIf="isClearbtnVisi" class="button-box"  (click)="clear()" mat-stroked-button one-button-rounded one-secondary-button>
      Clear
    </button>
</div>