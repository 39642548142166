import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslatedBundleGuard } from '@dialog-eservices-enablement/angular-components';
import { CustomerNotificationComponent } from './features/customer-notification/customer-notification.component';
import { CustomerNotificationDetailsComponent } from './features/customer-notification/customer-notification-details/customer-notification-details.component';
import { CustomerContactDetailsComponent } from './features/customer-notification/customer-contact-details/customer-contact-details.component';
import { NondialogCustomerWelcomeComponent } from './features/customer-notification/nondialog-customer-welcome/nondialog-customer-welcome.component';
export const appRouterModule: Routes = [
  {
    path: '',
    redirectTo: '/customer-notification',
    pathMatch: 'full'
  },
  {
    path: 'customer-notification',
    component: CustomerNotificationComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'customer-notification-details',
    component: CustomerNotificationDetailsComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'contact-details',
    component: CustomerContactDetailsComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'welcome',
    component: NondialogCustomerWelcomeComponent,
    canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: '**',
    redirectTo: '/customer-notification',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRouterModule, { relativeLinkResolution: 'legacy', scrollPositionRestoration:'enabled' })],
  exports: [RouterModule]
})
export class AppRouterModule {}
