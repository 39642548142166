import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetAffiliatesContent as Affiliate } from '@dialog-eservices-enablement/dialog-api-library';

// Use the RegionDialogService to open the dialog, because it tracks whether it is open, which e.g. a guard needs to know

export interface RegionDialogInput {
  affiliates: Affiliate[];
  preSelectedAffiliate: Affiliate; // TODO Atm we cannot have a preselectedAffiliate, because we redirect to the default bundle
}

export interface RegionDialogOutput {
  selectedAffiliate: Affiliate;
  rememberMyLocation: boolean;
}

@Component({
  selector: 'dl-region-dialog',
  templateUrl: './region-dialog.component.html',
  styleUrls: ['./region-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RegionDialogComponent implements OnInit {
  public constructor(@Inject(MAT_DIALOG_DATA) public data: RegionDialogInput, private dialogRef: MatDialogRef<RegionDialogComponent>) {}
  public formDisabled = false;
  public mappedAffiliates = this.data.affiliates.map(affiliate => ({
    label: affiliate.label,
    value: affiliate
  }));
  public isValidAffiliate: boolean;

  public affiliateControl = new FormControl();
  public affiliateFilterOptions: Observable<string[]>;

  public rememberLocationControl = new FormControl(true);

  public ngOnInit(): void {
    this.affiliateFilterOptions = this.resetFilter(this.affiliateControl);
  }
  public togglePanel(event: Event, isOpen: boolean, trigger: MatAutocompleteTrigger, type: string): void {
    event.stopPropagation();
    if (isOpen) {
      trigger.closePanel();
    } else {
      this.affiliateFilterOptions = this.resetFilter(this.affiliateControl);
      trigger.openPanel();
    }
  }
  public resetFilter(formControlEditableDropdown: FormControl): Observable<string[]> {
    return formControlEditableDropdown.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }
  public filter(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    const result = this.mappedAffiliates.filter(option => option.value.label.toLowerCase().indexOf(filterValue) === 0);

    const filteredAffiliates = result && result.length === 1 && result[0].value.affiliate === value ? this.mappedAffiliates : this.mappedAffiliates.filter(option => option.value.label.toLowerCase().indexOf(filterValue) === 0);
    return filteredAffiliates.map(affilate => affilate.label);
  }

  public isValid(inputAffiliate: string): boolean {
    const filteredValues: string[] = this.filter(inputAffiliate);
    return filteredValues.length > 0 && inputAffiliate === filteredValues[0];
  }
  public onContinueClicked(): void {
    const chosenAffiliate = this.data.affiliates.find(affilate => affilate.label === this.affiliateControl.value);
    const output: RegionDialogOutput = {
      selectedAffiliate: chosenAffiliate,
      rememberMyLocation: this.rememberLocationControl.value
    };
    this.dialogRef.close(output);
  }
}
