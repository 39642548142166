import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationSearchService {
  // Observable string sources
  private searchNotificationData = new Subject<string>();

  // Observable string streams
  public searchData$ = this.searchNotificationData.asObservable();

  // Service message commands
  public getSearchedData(text: string): void {
    this.searchNotificationData.next(text);
  }
}
