import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterOptions } from './notification-filter.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationFilterService {
  // Observable string sources
  private typeEle = {
    label: '',
    value: ''
  };
  private statusEle = {
    label: '',
    value: ''
  };
  private typeFilter = new BehaviorSubject(this.typeEle);
  private statusFilter = new BehaviorSubject(this.statusEle);
  private filterNotificationData = new Subject<FilterOptions>();
  // Observable string streams

  public typeFilterobservable$ = this.typeFilter.asObservable();
  public statusFilterobservable$ = this.statusFilter.asObservable();
  public filterData$ = this.filterNotificationData.asObservable();
  // Service message commands

  public setTypeName(d: any): void {
    this.typeEle.label = d;
    this.typeEle.value = d;
    this.typeFilter.next(this.typeEle);
    this.typeEle = { label: '', value: '' };
  }

  public setStatusName(d: any): void {
    this.statusEle.label = d;
    this.statusEle.value = d;
    this.statusFilter.next(this.statusEle);
    this.statusEle = { label: '', value: '' };
  }
  public getfilteredData(filterDataObject: any): void {
    this.filterNotificationData.next(filterDataObject);
    // console.log('filters in service', filterDataObject);
  }
}
