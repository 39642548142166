import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationSearchService } from './notification-search.service';

@Component({
  selector: 'dl-notification-search',
  templateUrl: './notification-search.component.html',
  styleUrls: ['./notification-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSearchComponent implements OnInit {
  public notificationSearch = new FormControl();
  public constructor(private notificationSearchService: NotificationSearchService) {}
  public ngOnInit(): void {}
  public applyFilter(filterValue: string): void {
    // console.log('search value', filterValue);
    this.notificationSearchService.getSearchedData(filterValue.trim().toLocaleLowerCase());
  }
}
